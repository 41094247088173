angular.module('ui.bootstrap.datepicker').config(['$provide',function($provide) {
  $provide.decorator('uibDatepickerDirective', function($delegate) {
    var directive = $delegate[0];
    directive.scope.availabilityCache = '<';
    directive.scope.predateSelectorId = '<';
    var link = directive.link;

    directive.compile = function() {
      return function(scope, element, attrs, ctrls) {
        link.apply(this, arguments);

        var datepickerCtrl = ctrls[0];
        var ngModelCtrl = ctrls[1];

        scope.numberIn = function(number) {
          if(!angular.isString(number)) {
            return number;
          }
          number=number.replace(/^0+/g,'');
          if(number.length===0) {
            return 0;
          }
          return parseInt(number,10);
        };

        scope.padTwoDigits=function(n) {
          var result=n;
          if(n<10) {
            result='0'+n;
          }
          return result;
        };

        scope.dateFromJava=function(input) {
          var result;
          if(input) {
            result=input.getFullYear()+'-'+
              scope.padTwoDigits(input.getMonth()+1)+'-'+
              scope.padTwoDigits(input.getDate());
          } else {
            result='';
          }
          return result;
        };

        scope.dateToJava=function(input) {
          if(input) {
            return new Date(
              scope.numberIn(input.substr(0,4)),
              scope.numberIn(input.substr(5,2))-1,
              scope.numberIn(input.substr(8,2))
            );
          }
        };

        scope.getRequestId = function(yearAndMonth) {
          var requestId=false;
          if(angular.isDefined(scope.predateSelectorId) && scope.predateSelectorId!==null) {
            requestId=yearAndMonth+scope.predateSelectorId;
          } else {
            requestId=yearAndMonth;
          }
          return requestId;
        };

        scope.onMouseEnter = function(rows,selectedDt,hoverDt) {
          var i,j,row,dt,jDtDate,dtDate,jFixedEnddate,jSelectedDate;
          var jHoverDate=hoverDt.date;
          var hoverDate=scope.dateFromJava(jHoverDate);
          var fixedEnddate=null;
          var hasSelectedDt=angular.isDefined(selectedDt) && selectedDt!==null && selectedDt.selected;
          if(hasSelectedDt) {
            jSelectedDate=selectedDt.date;
          } else {
            var requestId=scope.getRequestId(hoverDate.substr(0,7));
            if(requestId in scope.availabilityCache && hoverDate in scope.availabilityCache[requestId] && scope.availabilityCache[requestId][hoverDate].fixedEnddates && Object.keys(scope.availabilityCache[requestId][hoverDate].fixedEnddates).length===1) {
              fixedEnddate=Object.keys(scope.availabilityCache[requestId][hoverDate].fixedEnddates)[0];
              jFixedEnddate=scope.dateToJava(fixedEnddate);
            }
          }
          for(i in rows) {
            row=rows[i];
            for(j in row) {
              dt=row[j];
              jDtDate=dt.date;
              dtDate=scope.dateFromJava(jDtDate);
              dt.hover={
                'hover-selection':(hasSelectedDt && jDtDate>jSelectedDate && jDtDate<jHoverDate) || (fixedEnddate===hoverDate && fixedEnddate===dtDate && !hoverDate.disabled) || (fixedEnddate!==null && jDtDate>jHoverDate && jDtDate<jFixedEnddate),
                'hover-end-selection':(hasSelectedDt && jDtDate>jSelectedDate && dtDate===hoverDate) || (fixedEnddate!==null && jDtDate>jHoverDate && dtDate===fixedEnddate),
                'hover-start-selection':((hasSelectedDt && jDtDate<selectedDt.date && dtDate===hoverDate) || (!hasSelectedDt && dtDate===hoverDate && fixedEnddate!==hoverDate)) && !hoverDt.disabled
              };
            }
          }
        };

        scope.onMouseLeave = function(rows) {
          for(var i in rows) {
            var row=rows[i];
            for(var j in row) {
              row[j].hover={};
            }
          }
        };

        if (ngModelCtrl) {
          // Listen for 'refreshDatepickers' event...
          scope.$on('refreshDatepickers', function() {
            datepickerCtrl.refreshView();
          });
        }
      };
    };
    return $delegate;
  });
}]);
