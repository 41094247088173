angular.module('app').controller('DetailsModalController',['$scope','webservice','$window',
                                            function($scope,webservice,$window) {
  var script='public/reservationC';

  $scope.parentProceed = function(shortTermBasket,collections,params,ignoreZeroQuantities) {
    var model={};
    webservice.exclusiveCall('validateEvents',$scope.ignoreZeroQuantities(params,ignoreZeroQuantities),script,model).then(function() {
      for(var i in model.items) {
        collections.items[i]=model.items[i];
      }
      $scope.$close({'shortTermBasket':shortTermBasket,'collections':collections});
      var breakpointMd=1024;
      if ($window.innerWidth<breakpointMd) {
        $scope.scrollToId('shopping_cart_title');
      }
    });
  };

  $scope.confirmWording={'en':'Validate','nl':'Valideer','fr':'Valider'};

}]);
