angular.module('app').controller('SiteFinishedPageController',['$scope','$timeout',
                                            function($scope,$timeout) {
  // var parentModel=$scope.model;
  // $scope.parentModel=parentModel;
  // var model={};
  // $scope.model=model;

  $timeout(function() {
    $scope.redirectToStart();
  },7000);

}]);
