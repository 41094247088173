angular.module('app').controller('GlobalController',['$stateParams','$scope','$window','$state','$location','$log','$q','$sce','$anchorScroll','tools','formatting','config','contactsService','modelstore','webservice','parameters','user','Idle','$uibModal','localStorageService','errorlog','ctep','stateService','windowService',
                                            function($stateParams,$scope,$window,$state,$location,$log,$q,$sce,$anchorScroll,tools,formatting,config,contactsService,modelstore,webservice,parameters,user,Idle,$uibModal,localStorageService,errorlog,ctep,stateService,windowService) {
  $scope.tools = tools;
  $scope.parameters = parameters;
  $scope.user = user;
  $scope.config = config;
  $scope.contactsService=contactsService;
  var script='public/reservationC';
  var model={};
  $scope.model=model;
  $scope.stateparams={};
  $scope.windowIsLoading=true;
  $scope.askLeavePageBeforeUnload=false;
  var labels=['labelen','labelnl','labelfr'];

  $window.onbeforeunload = function(e) {
    $window.onunload = function() {
      if(angular.isDefined($scope.ffo)) {
        $window.localStorage['unloadTime_'+$scope.ffo]=angular.toJson(new Date());
      }
    };
    var result=null;
    if($scope.askLeavePageBeforeUnload) {
      result=e;
    }
    return result;
  };

  $scope.setLeavePageBeforeUnloadTo = function(status) {
    $scope.askLeavePageBeforeUnload=status;
  };

  $scope.matchesFormula = function(unt,frm) {
    return unt && frm &&
      unt.substr(0,3)===frm.substr(0,3) &&
      (unt.substr(3,3)==='***' || frm.substr(3,3)==='***' || unt.substr(0,6)===frm.substr(0,6));
  };

  $scope.getFilteredFca = function() {
    var result=[];
    if('notDelArrayFCA' in parameters && 'formbuttons' in model && 'selectionFormbuttons' in model) {
      result=parameters.notDelArrayFCA.reduce(function(acc,fca) {
        if(((fca.id.substr(0,2)===$scope.ffo && fca.field2==='1') || (angular.isDefined(model.specificSaleFormbuttonId) && model.specificSaleFormbuttonId!=='')) && $scope.getFcaFormbuttons(fca.id).length>0) {
          acc.push(fca);
        }
        return acc;
      },result);
      if($scope.getFcaFormbuttons($scope.ffo+'***').length>0) {
        var selectionFormbuttonsFca=$scope.selectionFormbuttonsFca;
        var fca=model.selectionFormbuttons.reduce(function(acc,formbuttonId) {
          if(acc===null && angular.isDefined(model.formbuttons[formbuttonId])) {
            acc=parameters.FCA[model.formbuttons[formbuttonId].fca];
          }
          return acc;
        },null);
        if(fca!==null) {
          selectionFormbuttonsFca.labelen='Your selection';
          selectionFormbuttonsFca.labelnl='Uw selectie';
          selectionFormbuttonsFca.labelfr='Votre selection';
          for(var field in fca) {
            if(angular.isUndefined(selectionFormbuttonsFca[field])) {
              selectionFormbuttonsFca[field]=fca[field];
            }
          }
          result.push(selectionFormbuttonsFca);
        }
      }
    }
    return result;
  };

  $scope.getFcaFormbuttons = function(fca) {
    var result=[];
    var i,formbutton,isFilteredFormbutton;
    for(i in model.formbuttons) {
      formbutton=model.formbuttons[i];
      isFilteredFormbutton=model.selectionFormbuttons.indexOf(parseInt(formbutton.id,10))>=0;
      if(((formbutton.fca===fca && !isFilteredFormbutton) || (fca===$scope.ffo+'***' && isFilteredFormbutton)) && $scope.filterOnDaydurationAndInterval(formbutton)) {
        result.push(formbutton);
      }
    }
    return result;
  };

  $scope.filterOnDaydurationAndInterval = function(formbutton) {
    return (formbutton.dayduration==='0' || tools.amountIn(formbutton.dayduration)===((model.multipleDays==='1')?Math.round((model.enddate-model.startdate)/(1000*60*60*24))+1:1)) &&
      (formbutton.fixeddateinterval==='' || formbutton.fixeddateinterval===model.predateSelectorId || formbutton.fixeddateinterval===$scope.predateSelectors[model.predateSelectorId].order);
  };

  $scope.getActivityAvailabilities = function() {
    return ('activityAvailabilities' in model)?model.activityAvailabilities:model.hrsLists;
  };

  $scope.trustLabelsAsHtml = function(page,formbuttoncomponents) {
    var i,j,formbuttoncomponent,label,trustedLabel;
    for(i in formbuttoncomponents) {
      formbuttoncomponent=formbuttoncomponents[i];
      for(j in labels) {
        label=labels[j];
        trustedLabel=page+label;
        if(formbuttoncomponent[label]==='') {
          formbuttoncomponent[label]=$scope.getFormbuttoncomponentTitle(formbuttoncomponent,label);
        }
        formbuttoncomponent[trustedLabel]=$sce.trustAsHtml(formbuttoncomponent[label]);
      }
    }
  };

  $scope.getNetPrice =function(item) {
    if(item) {
      return tools.amountIn(item.nettopay);
    }
  };

  $scope.getGrossPrice =function(item) {
    if(item) {
      return Math.max(tools.amountIn(item.grossunitprice),tools.amountIn(item.standardunitprice))*tools.amountIn(item.quantity);
    }
  };

  $scope.getHotelreservationproductsNetPrice =function(hotelreservationcategory,hpdId) {
    if(hotelreservationcategory) {
      return tools.inverseJoin(tools.match(tools.join(hotelreservationcategory,'hotelreservationproducts',model),{'hpd':hpdId}),'items','item',model).reduce(function(acc,item) {
        return acc+tools.amountIn(item.nettopay);
      },0.0);
    }
  };
  $scope.getHotelreservationproductsGrossPrice =function(hotelreservationcategory,hpdId) {
    if(hotelreservationcategory) {
      return tools.inverseJoin(tools.match(tools.join(hotelreservationcategory,'hotelreservationproducts',model),{'hpd':hpdId}),'items','item',model).reduce(function(acc,item) {
        return acc+tools.amountIn(item.grossunitprice)*tools.amountIn(item.quantity);
      },0.0);
    }
  };

  $scope.getItems = function(formbuttoncomponentReferences,localModel) {
    if(angular.isUndefined(localModel)) {
      localModel=model;
    }
    if(angular.isUndefined(formbuttoncomponentReferences)) {
      formbuttoncomponentReferences=[].concat.apply([],localModel.longTermBasket);
    }
    var formbuttoncomponentReferencesLength=formbuttoncomponentReferences.length;
    var i,formbuttoncomponentReference,hotelreservationcategory,hotelreservationproducts;
    var recordsWithItem=[];
    for(i=0;i<formbuttoncomponentReferencesLength;i++) {
      formbuttoncomponentReference=formbuttoncomponentReferences[i];
      if(formbuttoncomponentReference.formbuttoncomponent in model.formbuttoncomponents) {
        switch(model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param1) {
          case 'AC':
            recordsWithItem=recordsWithItem.concat(tools.join(localModel.activities[formbuttoncomponentReference.record],'participants',localModel));
            break;
          case 'ME':
            recordsWithItem=recordsWithItem.concat(tools.join(localModel.meals[formbuttoncomponentReference.record],'dishes',localModel));
            break;
          case 'HS':
            hotelreservationcategory=localModel.hotelreservationcategories[formbuttoncomponentReference.record];
            recordsWithItem=recordsWithItem.concat(hotelreservationcategory);
            hotelreservationproducts=tools.join([hotelreservationcategory],'hotelreservationproducts',localModel);
            if(tools.getLength(hotelreservationproducts)===0 && tools.getLength(model.backupCollections)>0) {
              hotelreservationproducts=tools.match(model.backupCollections.hotelreservationproducts,{'reservationcategory':hotelreservationcategory.id});
            }
            recordsWithItem=recordsWithItem.concat(hotelreservationproducts);
            break;
          case 'PR':
            recordsWithItem=recordsWithItem.concat(localModel.products[formbuttoncomponentReference.record]);
            break;
          default:
            break;
        }
      }
    }
    return recordsWithItem.map(function(recordWithItem) {
      var item={'quantity':0};
      if(angular.isDefined(recordWithItem) && angular.isDefined(recordWithItem.item)) {
        item=localModel.items[recordWithItem.item];
      }
      return item;
    }).filter(function(item) {
      return tools.numberIn(item.quantity)>0;
    });
  };

  $scope.getGrossReservationPrice = function(formbuttoncomponentReferences) {
    var price=0.0;
    var items=$scope.getItems(formbuttoncomponentReferences);
    var itemLength=items.length;
    var i,item;
    for(i=0;i<itemLength;i++) {
      item=items[i];
      price+=Math.max(item.grossunitprice,item.standardunitprice)*item.quantity;
    }
    return price;
  };

  $scope.getReservationPrice = function(formbuttoncomponentReferences) {
    var price=0.0;
    var items=$scope.getItems(formbuttoncomponentReferences);
    var itemLength=items.length;
    var i;
    for(i=0;i<itemLength;i++) {
      price+=items[i].nettopay;
    }
    return price;
  };

  $scope.getTitleSpecificSaleFormbutton = function(shortOrLong) {
    var result='';
    if(angular.isDefined(model.specificSaleFormbutton) && model.specificSaleFormbutton!==null) {
      result=$scope.getFormbuttonTitle(model.specificSaleFormbutton,model.specificSaleFormbuttoncomponents,shortOrLong);
    }
    return result;
  };

  $scope.getTitleLongTermbasketContent = function(longTermBasketContent) {
    var result='';
    var formbuttoncomponents=[];
    if(angular.isDefined(longTermBasketContent) && longTermBasketContent!==null) {
      for(var i=0;i<longTermBasketContent.length;i++) {
        formbuttoncomponents.push(model.formbuttoncomponents[longTermBasketContent[i].formbuttoncomponent]);
      }
      var formbuttoncomponent=tools.first(formbuttoncomponents);
      if(angular.isDefined(formbuttoncomponent) && 'formbutton' in formbuttoncomponent) {
        var formbutton=model.formbuttons[formbuttoncomponent.formbutton];
        result=$scope.getFormbuttonTitle(formbutton,formbuttoncomponents);
      }
    }
    return result;
  };

  $scope.getFormbuttonTitle = function(formbutton,formbuttoncomponents,shortOrLong) {
    var result={};
    var label='label'+user.lang;
    if(formbutton.singlequantity==='1' && formbutton[label]!=='') {
      result=formbutton[label];
    } else {
      var formbuttoncomponent,i;
      for(i=0;i<formbuttoncomponents.length;i++) {
        formbuttoncomponent=formbuttoncomponents[i];
        result[formbuttoncomponent.sort]=$scope.getFormbuttoncomponentTitle(formbuttoncomponent,label);
      }
      result=Object.values(result);
      if(shortOrLong==='Long') {
        result=Array.from(new Set(result)).join(' + ');
      } else {
        result=result[0];
      }
    }
    return result;
  };

  $scope.getFormbuttoncomponentTitle = function(formbuttoncomponent,label) {
    var result='';
    if(formbuttoncomponent[label]==='') {
      if(formbuttoncomponent.param1==='AC') {
        result=parameters.ACT[formbuttoncomponent.param2.substr(0,3)][label];
        if(angular.isDefined(parameters.FRM[formbuttoncomponent.param2]) && parameters.FRM[formbuttoncomponent.param2].field3==='1') {
          result=result+' '+parameters.FRM[formbuttoncomponent.param2][label];
        }
      } else if(formbuttoncomponent.param1==='ME') {
        result=parameters.DSH[formbuttoncomponent.param2][label];
      } else if(formbuttoncomponent.param1==='HS') {
        result=parameters.HSC[formbuttoncomponent.param2][label];
      } else if(formbuttoncomponent.param1==='PR') {
        result=parameters.PRD[formbuttoncomponent.param2][label];
      }
    } else {
      result=formbuttoncomponent[label];
    }
    return result;
  };

  $scope.getFcaTitle = function(fca) {
    var needsExtraWording=tools.getLength(model.selectionFormbuttons)>0 && fca.id!==$scope.ffo+'***';
    return (needsExtraWording?tools.lang({'en':'Extra ','nl':'Extra ','fr':''}):'')+tools.label(fca)+(needsExtraWording?tools.lang({'en':'','nl':'','fr':' Suppl\u00E9mentaires'}):'');
  };

  $scope.getTemplateimageIdLongTermBasketContent = function(longTermBasketContent) {
    var result='';
    if(angular.isDefined(longTermBasketContent) && longTermBasketContent!==null) {
      var formbuttoncomponent=model.formbuttoncomponents[tools.first(longTermBasketContent).formbuttoncomponent];
      if(angular.isDefined(formbuttoncomponent)) {
        var formbutton=model.formbuttons[formbuttoncomponent.formbutton];
        if(angular.isDefined(formbutton)) {
          result=formbutton.templateimageId;
        }
      }
    }
    return result;
  };

  $scope.dismissModal = function() {
    if($scope.modal!==null) {
      $scope.modal.dismiss();
      $scope.modal=null;
    }
  };

  $scope.sort = function(a) {
    return parseInt(a.sort,10);
  };

  $scope.sortFombuttonReferences = function(a,b) {
    var result=0;
    var aFormbuttoncomponent=model.formbuttoncomponents[a.value];
    var bFormbuttoncomponent=model.formbuttoncomponents[b.value];
    if(angular.isDefined(aFormbuttoncomponent) && angular.isDefined(bFormbuttoncomponent)) {
      result=aFormbuttoncomponent.sort<bFormbuttoncomponent.sort?-1:1;
    }
    return result;
  };

  $scope.isLoading=false;
  $scope.load = function() {
    modelstore.cleanModel(['formbuttons','formpackets','formbuttoncomponents'],model);
    parameters.structuredCombinations={};
    $scope.initHardcodedFormbuttoncomponents();
    var paramsForLoad={'mode':config.get('mode'),'ffo':$scope.ffo,'campaignSuffix':model.campaignSuffix,'campaignCode':model.campaignCode};
    if($scope.isSpecificSale) {
      paramsForLoad.formbuttonId=model.specificSaleFormbuttonId;
    }
    if($scope.isUpdateForEmp) {
      paramsForLoad.visitId=$scope.stateparams.visitId;
      paramsForLoad.token=$scope.stateparams.token;
    }
    $scope.isLoading=true;
    var result=webservice.call('load',paramsForLoad,script,model).then(function(response) {
      $scope.isLoading=false;
      var defered=$q.defer();
      if(response) {
        var i,j,key,value,combination,country,countryCallingCode;
        var parameterTables=['ACT','FRM','UNT','PRD','HRS','DSH','HRA','HSC','HPD','FFO','FCA'];
        for(i in parameterTables) {
          key=parameterTables[i];
          value=model[key];
          parameters[key]=value;
          parameters['notDel'+key]=webservice.tools.matchForObject(value,{'del':'0'});
          parameters['array'+key]=webservice.tools.toArray(value);
          parameters['notDelArray'+key]=webservice.tools.toArray(webservice.parameters['notDel'+key]);
        }
        for(i in model.combinations) {
          combination=model.combinations[i];
          if(!(combination.id in parameters.structuredCombinations)) {
            parameters.structuredCombinations[combination.id]={};
          }
          if(!(combination.id1 in parameters.structuredCombinations[combination.id])) {
            parameters.structuredCombinations[combination.id][combination.id1]=[];
          }
          parameters.structuredCombinations[combination.id][combination.id1].push(combination.id2);
        }
        parameters.countryCallingCodes={};
        parameters.countries=model.countries;
        for(i in model.countries) {
          country=model.countries[i];
          for(j=0;j<country.countryCallingCodes.length;j++) {
            countryCallingCode=country.countryCallingCodes[j];
            key=country.id+countryCallingCode;
            parameters.countryCallingCodes[key]=tools.clone(country);
            parameters.countryCallingCodes[key].id=key;
            parameters.countryCallingCodes[key].countryId=country.id;
            parameters.countryCallingCodes[key].countryCallingCode=countryCallingCode;
          }
        }
        if(model.country!==null) {
          model.country=angular.isDefined(parameters.countries[model.country])?model.country:null;
        }
        if(model.campaignPromotion!==null) {
          modelstore.addRecord('promotions',model.campaignPromotion,model);
          var campaignPromotionrestrictions=tools.join(model.campaignPromotion,'promotionrestrictions',model);
          model.campaignFrmIds=campaignPromotionrestrictions.filter(function(promotionrestriction) {
            return promotionrestriction.rst==='ACTIV';
          }).map(function(promotionrestriction) {
            return promotionrestriction.parameter;
          });
          model.campaignOnlyForOnlinePayment=tools.match(campaignPromotionrestrictions,{'rst':'ONLINE'}).length!==0;
        }
        for(i in model.banners) {
          var banner=model.banners[i];
          for(j in config.langs) {
            banner['trustedRendered'+config.langs[j]]=$sce.trustAsHtml(banner['rendered'+config.langs[j]]);
          }
        }
        if($scope.isUpdateForEmp) {
          model.visit=model.visits[$scope.stateparams.visitId];
        }
        if(model.predateSelectors!==null) {
          $scope.predateSelectors=model.predateSelectors;
        }
        defered.resolve(true);
      } else {
        defered.reject(false);
      }
      return defered.promise;
    });
    return result;
  };

  $scope.datesAreValid = function(dates) {
    var result=false;
    if(angular.isDefined(dates.startdate) && (model.multipleDays==='0' || angular.isDefined(dates.enddate))) {
      var startdate=tools.dateFromJava(dates.startdate);
      var enddate=tools.dateFromJava(model.multipleDays==='0'?dates.startdate:dates.enddate);
      result=startdate.length===10 && enddate.length===10 && (model.multipleDays==='0' || dates.enddate>=dates.startdate);
    }
    return result;
  };

  $scope.deleteCollections = function(collections) {
    angular.forEach(collections,function(records,collectionName) {
      for(var i in records) {
        modelstore.removeRecord(collectionName,records[i].id,model);
      }
    });
  };

  $scope.deleteFormbuttoncomponentReference = function(formbuttoncomponentReference) {
    var param1;
    var formbuttoncomponentId=formbuttoncomponentReference.formbuttoncomponent;
    var recordId=formbuttoncomponentReference.record;
    if(angular.isDefined(model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent])) {
      param1=model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param1;
    } else if(tools.getLength(tools.match(model.activities,{'record':recordId,'formbuttoncomponent':formbuttoncomponentId}))>0) {
      param1='AC';
    } else if(tools.getLength(tools.match(model.meals,{'record':recordId,'formbuttoncomponent':formbuttoncomponentId}))>0) {
      param1='ME';
    } else if(tools.getLength(tools.match(model.products,{'record':recordId,'formbuttoncomponent':formbuttoncomponentId}))>0) {
      param1='PR';
    } else if(tools.getLength(tools.match(model.hotelreservationcategories,{'record':recordId,'formbuttoncomponent':formbuttoncomponentId}))>0) {
      param1='HS';
    } else if(tools.getLength(tools.match(model.packages,{'record':recordId,'formbuttoncomponent':formbuttoncomponentId}))>0) {
      param1='PA';
    }
    var deletedItems=[];
    if(param1==='AC') {
      var activity=model.activities[recordId];
      var participants=tools.join([activity],'participants',model);
      deletedItems=tools.inverseJoin(participants,'items','item',model);
      $scope.deleteCollections({'activities':[activity],'participants':participants,'items':deletedItems});
    } else if(param1==='ME') {
      var meal=model.meals[recordId];
      var dishes=tools.join([meal],'dishes',model);
      deletedItems=tools.inverseJoin(dishes,'items','item',model);
      $scope.deleteCollections({'meals':[meal],'dishes':dishes,'items':deletedItems});
    } else if(param1==='PR') {
      var product=model.products[recordId];
      deletedItems=[model.items[product.item]];
      $scope.deleteCollections({'products':[product],'items':deletedItems});
    } else if(param1==='HS') {
      var hotelreservationcategory=model.hotelreservationcategories[recordId];
      var hotelreservationproducts=tools.join([hotelreservationcategory],'hotelreservationproducts',model);
      deletedItems=tools.inverseJoin([hotelreservationcategory],'items','item',model).concat(tools.inverseJoin(hotelreservationproducts,'items','item',model));
      $scope.deleteCollections({'hotelreservationcategories':[hotelreservationcategory],'hotelreservationproducts':hotelreservationproducts,'items':deletedItems});
    } else if(param1==='PA') {
      $scope.deleteCollections({'packages':[model.packages[recordId]]});
    }
    $scope.pushGTMRemoveFromCart(deletedItems);
  };

  $scope.deleteLongTermBasketContent = function($index,longTermBasketContent) {
    for(var i in longTermBasketContent) {
      $scope.deleteFormbuttoncomponentReference(longTermBasketContent[i]);
    }
    model.longTermBasket.splice($index,1);
    var params=$scope.createReservationParams([],[],{},false);
    if(model.campaignOnlyForOnlinePayment) {
      params.visit.campaign='';
    } else {
      params.visit.campaign=model.campaignCode;
    }
    webservice.call('calculatePrices',params,script,model);
  };

  $scope.getHraPerHotel = function() {
    var result;
    if(model.predateSelectorId!==null && angular.isDefined(model.predateSelectorId) && $scope.predateSelectors!==null && (model.predateSelectorId in $scope.predateSelectors)) {
      result=$scope.predateSelectors[model.predateSelectorId].hraPerHotel;
    } else {
      result=model.hraPerHotel;
    }
    return result;
  };

  $scope.gettingAvailabilities=false;
  $scope.onDateChange = function(dateName,newVal) {
    model.startdateISO=tools.dateFromJava(model.startdate);
    model.enddateISO=tools.dateFromJava(model.enddate);
    var result;
    var dates={
      'startdate':model.startdate,
      'enddate':model.enddate
    };
    var newDate=new Date();
    newDate.setTime(newVal);
    dates[dateName]=newDate;
    if(!model.initializing && $scope.datesAreValid(dates) && (!$scope.isSpecificSale || $scope.isKioskMode)) {
      modelstore.cleanModel(['formbuttons','formbuttonrelations','formbuttoncomponents','templates'],model);
      $scope.initHardcodedFormbuttoncomponents();
      var getAvailabilitiesParams={
        'lang':user.lang,
        'ffo':$scope.ffo,
        'mode':$scope.mode,
        'hraPerHotel':$scope.getHraPerHotel(),
        'startdate':tools.dateFromJava(dates.startdate),
        'enddate':tools.dateFromJava(model.multipleDays==='0'?dates.startdate:dates.enddate),
        'contactexternalids':tools.toArray(model.contactexternalids)
      };
      $scope.gettingAvailabilities=true;
      result=webservice.call('getAvailabilities',getAvailabilitiesParams,script,model).then(function(response) {
        $scope.gettingAvailabilities=false;
        if(response) {
          if(model.fca==='') {
            model.fca=tools.minInt($scope.getFilteredFca(),'field1').id;
          }
          model.days=[];
          var d=new Date();
          d.setTime(model.startdate.getTime());
          var enddate=model.multipleDays==='0'?model.startdate:model.enddate;
          for(;d<=enddate;d.setDate(d.getDate()+1)) {
            model.days.push(tools.dateFromJava(d));
          }
          var i,j,template,formbuttonsWithThisTemplate,lang,replacedRenderedTemplate,longTermBasketContent,formbuttoncomponentReference,param1,activity,frm,meal,hotelreservationcategory;
          var activityAvailabilities=$scope.getActivityAvailabilities();
          for(i in model.templates) {
            template=model.templates[i];
            if('rendereden' in template) {
              formbuttonsWithThisTemplate=tools.match(model.formbuttons,{'template':template.id});
              for(j in config.langs) {
                lang=config.langs[j];
                replacedRenderedTemplate=tools.replaceAll(model.priceReplace,template['rendered'+lang]);
                if(formbuttonsWithThisTemplate.length===1 && formbuttonsWithThisTemplate[0].singlequantity==='1') {
                  replacedRenderedTemplate=replacedRenderedTemplate.replace('%P=PA%',model.packPrices[formbuttonsWithThisTemplate[0].id]);
                }
                template['trustedRendered'+lang]=$sce.trustAsHtml(replacedRenderedTemplate);
              }
            }
          }
          var existingLongTermBasket=tools.clone(model.longTermBasket);
          var index=0;
          for(i=0;i<existingLongTermBasket.length;i++) {
            longTermBasketContent=existingLongTermBasket[i];
            for(j=0;j<longTermBasketContent.length;j++) {
              formbuttoncomponentReference=longTermBasketContent[j];
              if(angular.isDefined(model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent])) {
                param1=model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param1;
                if(param1==='AC') {
                  activity=model.activities[formbuttoncomponentReference.record];
                  frm=activity.frm;
                  if(!(frm in activityAvailabilities) ||
                      !(activity.day in activityAvailabilities[frm].days) ||
                      !(activity.hrs in activityAvailabilities[frm].days[activity.day].hrs) ||
                      !activityAvailabilities[frm].days[activity.day].hrs[activity.hrs].available) {
                    $scope.deleteLongTermBasketContent(index,longTermBasketContent);
                    break;
                  }
                } else if(param1==='ME') {
                  meal=model.meals[formbuttoncomponentReference.record];
                  if(!(meal.day in model.restaurantAvailabilities)) {
                    $scope.deleteLongTermBasketContent(index,longTermBasketContent);
                    break;
                  }
                } else if(param1==='HS') {
                  hotelreservationcategory=model.hotelreservationcategories[formbuttoncomponentReference.record];
                  if(model.hotelAvailabilities[hotelreservationcategory.requestedhsc]<hotelreservationcategory.quantity ||
                      hotelreservationcategory.startdate!==model.startdateISO ||
                      hotelreservationcategory.enddate!==model.enddateISO) {
                    $scope.deleteLongTermBasketContent(index,longTermBasketContent);
                    break;
                  }
                }
              } else {
                $scope.deleteLongTermBasketContent(index,longTermBasketContent);
                break;
              }
              index++;
            }
          }
          $scope.$broadcast('loaded');
        }
        return response;
      });
    } else if(!model.initializing && $scope.datesAreValid(dates) && $scope.isSpecificSale && $scope.isSiteMode) {
      $scope.shortTermBasket=[];
      var existingLongTermBasket=tools.clone(model.longTermBasket);
      var index=0;
      for(var i=0;i<existingLongTermBasket.length;i++) {
        $scope.deleteLongTermBasketContent(index,existingLongTermBasket[i]);
      }
      modelstore.cleanModel(['activities','participants','meals','dishes','hotelreservationcategories','hotelreservationproducts','products','items'],model);
      model.detailsPageBasket=null;
      $scope.$broadcast('loaded');
      result=$q.resolve();
    } else {
      result=$q.reject(false);
    }
    return result;
  };

  $scope.termsAndConditionsAreAccepted = function() {
    var result;
    if($scope.termsAndConditionsLinks.length===0 || model.acceptTermsAndConditions) {
      result=$q.resolve();
    } else {
      errorlog.error(tools.lang({
        'en':'Please read and accept the terms and conditions',
        'nl':'Gelieve de voorwaarden te lezen en te aanvaarden',
        'fr':'Veuillez lire et accepter les conditions'
      }));
      result=$q.reject();
    }
    return result;
  };

  $scope.openVoucherModal = function() {
    var voucherResult1;
    if($scope.proposeVoucherPayment) {
      var onlyVoucher=model.longTermBasket.reduce(function(acc,shortTermBasket) {
        return acc && shortTermBasket.reduce(function(acc2,formbuttoncomponentReference) {
          var formbuttoncomponent=model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent];
          return acc2 && (formbuttoncomponent.param1==='PR' && formbuttoncomponent.param2==='VOU');
        },true);
      },true);
      if(onlyVoucher) {
        voucherResult1=$q.resolve([]);
      } else {
        voucherResult1=$scope.validateVisit().then(function() {
          $scope.dismissModal();
          $scope.modal=$uibModal.open({
            'templateUrl':'app/views/voucherQuestionModal.html',
            'scope':$scope,
            'size':'lg',
            'backdrop':'static',
            'keyboard':false
          });
          return $scope.modal.result.then(function(openUseVoucherModal) {
            $scope.modal=null;
            var voucherResult2;
            if(openUseVoucherModal) {
              $scope.modal=$uibModal.open({
                'templateUrl':'app/views/useVoucherModal.html',
                'scope':$scope,
                'size':'lg',
                'backdrop':'static',
                'keyboard':false
              });
              voucherResult2=$scope.modal.result.then(function(voucherCodes) {
                $scope.modal=null;
                return voucherCodes;
              },function() {
                $scope.modal=null;
                return $q.reject();
              });
            } else {
              voucherResult2=$q.resolve([]);
            }
            return voucherResult2;
          },function() {
            $scope.modal=null;
            return $q.reject();
          });
        });
      }
    } else {
      voucherResult1=$q.resolve([]);
    }
    return voucherResult1;
  };

  $scope.whitelistParams = function(params) {
    params.contacts=tools.whitelist(params.contacts,['firstname','name','gsm','email','country','address1','postalcode','address2','agreestomarketing','iscompany','vatnumber']);
    params.contactexternalids=tools.whitelist(params.contactexternalids,['contact','partner','externalid']);
    params.visit=tools['whitelist'+($scope.isUpdateForEmp?'':'NoId')](params.visit,['startdate','enddate','campaign','owningcontact','communicationcontact']);
    params.activities=tools.whitelist(params.activities,['frm','day','hrs','hasDummyHrs']);
    params.participants=tools.whitelist(params.participants,['activity','item','unt']);
    params.meals=tools.whitelist(params.meals,['res','day','hrs','delivery','hasDummyHrs']);
    params.dishes=tools.whitelist(params.dishes,['meal','item','dsh']);
    params.hotelreservationcategories=tools.whitelist(params.hotelreservationcategories,['htl','startdate','enddate','hra','requestedhsc','adultcount','childcount','item']);
    params.hotelreservationproducts=tools.whitelist(params.hotelreservationproducts,['reservationcategory','hpd','item']);
    params.products=tools.whitelist(params.products,['prd','item','comment']);
    params.items=tools.whitelist(params.items,['quantity','formbuttoncomponent']);
    return params;
  };

  $scope.basketContainsVoucher = function(basket) {
    var result=false;
    for(var i in basket) {
      var formbuttoncomponentReference=basket[i];
      if(angular.isObject(formbuttoncomponentReference) && (formbuttoncomponentReference.record in model.products) && model.products[formbuttoncomponentReference.record].prd==='VOU') {
        result=true;
      }
    }
    return result;
  };

  $scope.createReservationParams = function(voucherCodes,shortTermBasket,collections,final,newLongTermBasket) {
    var i,collection,record,hotelreservationcategory,hotelreservationproducts,key,item,param1;
    tools.initAttributes(model.personContact,{
      'firstname':'',
      'name':'',
      'email':'',
      'gsm':'',
      'country':''
    });
    if($scope.isKioskMode) {
      model.personContact.name='Kiosk';
    }
    var contacts;
    if($scope.isUpdateForEmp) {
      contacts=[model.contacts[model.visit.owningcontact]];
    } else {
      contacts=[model.personContact];
      if(model.visit.allowOwningcontactIsCompany && model.visit.owningcontactIsCompany && model.page==='credentials') {
        model.visit.owningcontact=model.companyContact.id;
        contacts.push(model.companyContact);
        model.visit.communicationcontact=model.personContact.id;
      } else {
        model.visit.owningcontact=model.personContact.id;
        model.visit.communicationcontact='0';
      }
    }
    model.visit.startdate=tools.dateFromJava(model.startdate);
    model.visit.enddate=tools.dateFromJava(model.multipleDays==='1'?model.enddate:model.startdate);
    model.visit.campaign='';
    if(model.campaignCode!=='') {
      model.visit.campaign=model.campaignCode;
    }
    var params={
      'mode':config.get('mode'),
      'ffo':$scope.ffo,
      'lang':user.lang,
      'url':config.get('url'),
      'hash':config.get('urlhash'),
      'voucherCodes':voucherCodes,
      'contacts':contacts,
      'contactexternalids':tools.toArray(model.contactexternalids),
      'visit':model.visit,
      'activities':{},
      'participants':{},
      'meals':{},
      'hotelreservationcategories':[],
      'hotelreservationproducts':[],
      'products':{}
    };
    if($scope.isUpdateForEmp) {
      params.token=$scope.stateparams.token;
    }
    var selectedHotelreservationcategories={};
    var longTermBasket=tools.clone(angular.isUndefined(newLongTermBasket)?model.longTermBasket:newLongTermBasket);
    if(angular.isDefined(shortTermBasket)) {
      longTermBasket.push(shortTermBasket);
    }
    var tempModel={
      'activities':tools.clone(model.activities),
      'participants':tools.clone(model.participants),
      'meals':tools.clone(model.meals),
      'dishes':tools.clone(model.dishes),
      'hotelreservationcategories':tools.clone(model.hotelreservationcategories),
      'hotelreservationproducts':tools.clone(model.hotelreservationproducts),
      'products':tools.clone(model.products),
      'items':tools.clone(model.items)
    };
    if(angular.isDefined(collections)) {
      var collectionName;
      for(collectionName in collections) {
        collection=collections[collectionName];
        for(i in collection) {
          record=collection[i];
          modelstore.addRecord(collectionName,record,tempModel);
        }
      }
    }
    angular.forEach(longTermBasket,function(longTermBasketContent) {
      angular.forEach(longTermBasketContent,function(formbuttoncomponentReference) {
        var formbuttoncomponentId=formbuttoncomponentReference.formbuttoncomponent;
        var recordId=formbuttoncomponentReference.record;
        if(angular.isDefined(model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent])) {
          param1=model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param1;
        } else if(tools.getLength(tools.match(model.activities,{'record':recordId,'formbuttoncomponent':formbuttoncomponentId}))>0) {
          param1='AC';
        } else if(tools.getLength(tools.match(model.meals,{'record':recordId,'formbuttoncomponent':formbuttoncomponentId}))>0) {
          param1='ME';
        } else if(tools.getLength(tools.match(model.products,{'record':recordId,'formbuttoncomponent':formbuttoncomponentId}))>0) {
          param1='PR';
        } else if(tools.getLength(tools.match(model.hotelreservationcategories,{'record':recordId,'formbuttoncomponent':formbuttoncomponentId}))>0) {
          param1='HS';
        } else if(tools.getLength(tools.match(model.packages,{'record':recordId,'formbuttoncomponent':formbuttoncomponentId}))>0) {
          param1='PA';
        }
        if(param1==='AC') {
          var activity=tempModel.activities[recordId];
          if(activity.frm!=='PRKTIC' || tools.amountIn(tempModel.items[tools.first(tools.join(activity,'participants',tempModel)).item].quantity)>=1.0) {
            params.activities[recordId]=activity;
          }
        } else if(param1==='ME') {
          params.meals[recordId]=tempModel.meals[recordId];
        } else if(param1==='HS') {
          selectedHotelreservationcategories[recordId]=tempModel.hotelreservationcategories[recordId];
        } else if(param1==='PR') {
          params.products[recordId]=tempModel.products[recordId];
        }
      });
    });
    params.activities=tools.toArray(params.activities);
    var allParticipants=tools.join(params.activities,'participants',tempModel);
    var participant,meal;
    for(i in allParticipants) {
      participant=allParticipants[i];
      if($scope.matchesFormula(participant.unt,tempModel.activities[participant.activity].frm)) {
        params.participants[participant.id]=participant;
      }
    }
    params.participants=tools.toArray(params.participants);
    params.meals=tools.toArray(params.meals);
    if($scope.isCatering()) {
      for(i in params.meals) {
        meal=params.meals[i];
        if(meal.hrs===null) {
          meal.hrs=model.restaurantAvailabilities[model.startdateISO][0];
        }
        if(meal.delivery===null) {
          meal.delivery='0';
        }
      }
    }
    params.dishes=tools.join(params.meals,'dishes',tempModel);
    selectedHotelreservationcategories=tools.toArray(selectedHotelreservationcategories);
    if(final) {
      var keyIndexedHotelreservationcategories={};
      for(i in selectedHotelreservationcategories) {
        hotelreservationcategory=selectedHotelreservationcategories[i];
        hotelreservationproducts=tools.join([hotelreservationcategory],'hotelreservationproducts',tempModel);
        key=hotelreservationcategory.htl+hotelreservationcategory.startdate+hotelreservationcategory.enddate+hotelreservationcategory.requestedhsc+tools.expandDigits(hotelreservationcategory.adultcount,2)+tools.expandDigits(hotelreservationcategory.childcount,2)+
          hotelreservationproducts.reduce(function(acc,hotelreservationproduct) {
            return acc+hotelreservationproduct.hpd;
          },'');
        if(key in keyIndexedHotelreservationcategories) {
          item=tempModel.items[keyIndexedHotelreservationcategories[key].item];
          item.quantity=tools.amountIn(item.quantity)+tools.amountIn(tempModel.items[hotelreservationcategory.item].quantity);
        } else {
          keyIndexedHotelreservationcategories[key]=hotelreservationcategory;
          params.hotelreservationcategories.push(hotelreservationcategory);
          params.hotelreservationproducts=params.hotelreservationproducts.concat(hotelreservationproducts);
        }
      }
    } else {
      params.hotelreservationcategories=selectedHotelreservationcategories;
      params.hotelreservationproducts=tools.join(params.hotelreservationcategories,'hotelreservationproducts',tempModel);
    }

    params.products=tools.toArray(params.products);
    params.items=tools.inverseJoin(params.participants,'items','item',tempModel)
      .concat(tools.inverseJoin(params.dishes,'items','item',tempModel))
      .concat(tools.inverseJoin(params.hotelreservationcategories,'items','item',tempModel))
      .concat(tools.inverseJoin(params.hotelreservationproducts,'items','item',tempModel))
      .concat(tools.inverseJoin(params.products,'items','item',tempModel));
    return $scope.whitelistParams(params);
  };

  $scope.ignoreZeroQuantities = function(collections,ignoreZeroQuantities) {
    ignoreZeroQuantities=angular.isUndefined(ignoreZeroQuantities)?false:ignoreZeroQuantities;
    if(ignoreZeroQuantities) {
      var i,j,k,collection,record;
      var visitCollections=['activities','participants','meals','dishes','hotelreservationcategories','hotelreservationproducts','products','items'];
      var tempModel={};
      for(i in visitCollections) {
        modelstore.addRecords(visitCollections[i],tools.clone(collections[visitCollections[i]]),tempModel,true);
      }
      var collectionsWithQuantity={'activities':'participants','meals':'dishes','hotelreservationcategories':'hotelreservationproducts','products':null};
      var sumQuantity,childRecords,childRecord;
      for(i in collectionsWithQuantity) {
        collection=tempModel[i];
        for(j in collection) {
          record=collection[j];
          if(collectionsWithQuantity[i]===null || i==='hotelreservationcategories') {
            sumQuantity=tools.amountIn(tempModel.items[record.item].quantity);
          } else {
            sumQuantity=tools.join(record,collectionsWithQuantity[i],tempModel).reduce(function(sumQuantity,childRecord) {
              return sumQuantity+tools.amountIn(tempModel.items[childRecord.item].quantity);
            },0.0);
          }
          if(sumQuantity===0.0) {
            if(collectionsWithQuantity[i]!==null) {
              childRecords=tools.join(record,collectionsWithQuantity[i],tempModel);
              for(k in childRecords) {
                childRecord=childRecords[k];
                modelstore.removeRecord('items',tempModel.items[childRecord.item].id,tempModel);
                modelstore.removeRecord(collectionsWithQuantity[i],childRecord.id,tempModel);
              }
            }
            if(collectionsWithQuantity[i]===null || i==='hotelreservationcategories') {
              modelstore.removeRecord('items',tempModel.items[record.item].id,tempModel);
            }
            modelstore.removeRecord(i,record.id,tempModel);
          }
        }
      }
      for(i in visitCollections) {
        collections[visitCollections[i]]=tempModel[visitCollections[i]];
      }
    }
    return collections;
  };

  $scope.validateVisit = function() {
    var params=$scope.createReservationParams([],[],{},false);
    params.visit.campaign='';
    return webservice.exclusiveCall('validateVisit',$scope.ignoreZeroQuantities(params,true),script,model);
  };

  $scope.resetKiosk = function() {
    if(ctep.claimedCtepterminal===null) {
      tools.redirect('setup');
    } else {
      tools.redirect('languages');
    }
  };

  $scope.redirectWhenFinished = function() {
    model.onlinepaymentState='initial';
    $scope.pushGTMPurchase();
    if($scope.isSiteMode) {
      $scope.resetModel();
      if(config.exists('finishedRedirect') && config.get('finishedRedirect')!==null) {
        if(angular.isObject(config.get('finishedRedirect'))) {
          $window.location.href=tools.lang(config.get('finishedRedirect'));
        } else {
          $window.location.href=$location.protocol()+'://'+$location.host()+config.get('finishedRedirect');
        }
      } else {
        tools.redirect('siteFinished');
      }
    } else if($scope.isKioskMode) {
      $scope.resetKiosk();
    }
  };

  $scope.ffoIsset = function() {
    return ('FFO' in parameters);
  };

  $scope.isCatering = function() {
    return ('FFO' in parameters)?parameters.FFO[$scope.ffo].field1==='1':false;
  };

  $scope.isDeliverable = function() {
    return ('FFO' in parameters)?parameters.FFO[$scope.ffo].field4==='1':false;
  };

  $scope.hasLocationPredateSelectors = function() {
    return ('FFO' in parameters)?parameters.FFO[$scope.ffo].field7==='1':false;
  };

  $scope.isPartnerIdAvailable = function() {
    return ('FFO' in parameters)?parameters.FFO[$scope.ffo].field11!=='' && parameters.FFO[$scope.ffo].field11!=='0':false;
  };

  $scope.partnerIdCheck = function() {
    return $scope.isPartnerIdAvailable() && parameters.FFO[$scope.ffo].field11!=='1';
  };

  $scope.isMultipleDays = function() {
    var result='0';
    if(angular.isDefined(model.predateSelector) && model.predateSelector!==null) {
      result=model.predateSelector.maxEnddateOffset>0?'1':'0';
    }
    return result;
  };

  $scope.isMultipleDaysDatepicker = function() {
    var result=true;
    if(angular.isDefined(model.predateSelector) && model.predateSelector!==null) {
      result=model.predateSelector.minEnddateOffset!==model.predateSelector.maxEnddateOffset && !model.predateSelector.selectFirstEnddate;
    }
    return result;
  };

  $scope.getTitle = function() {
    return config.exists('title')?config.get('title'):'Booking';
  };

  $scope.getFavicon = function() {
    return config.exists('favicon')?$sce.trustAsResourceUrl(config.get('favicon')):null;
  };

  $scope.getPhone = function() {
    var result;
    if('FFO' in parameters && parameters.FFO[$scope.ffo].field12!=='') {
      result=parameters.FFO[$scope.ffo].field12;
    } else {
      result=false;
    }
    return result;
  };

  $scope.getMaxHrsDate = function() {
    var result;
    if('FFO' in parameters && parameters.FFO[$scope.ffo].field9!=='') {
      result=parameters.FFO[$scope.ffo].field9;
    } else {
      result='23:59:59';
    }
    return result;
  };

  $scope.lastOrderHourPassed = function(delayBeforeClosing) {
    var result;
    if('FFO' in parameters && parameters.FFO[$scope.ffo].field9!=='') {
      result=tools.hoursSubtract(parameters.FFO[$scope.ffo].field9,delayBeforeClosing)<tools.now();
    } else {
      result=false;
    }
    return result;
  };

  $scope.setDelivery = function(delivery) {
    model.delivery=delivery;
    for(var i in model.meals) {
      model.meals[i].delivery=delivery;
    }
  };

  $scope.redirectToStart = function() {
    if($scope.skipDatesPage) {
      $scope.redirectToActivities();
    } else {
      $scope.redirectToDates();
    }
  };

  $scope.redirectToActivities = function() {
    $window.scrollTo(0,0);
    if($scope.isSpecificSale) {
      tools.redirect('detailsPage');
    } else {
      tools.redirect('activities');
    }
  };

  $scope.redirectToCredentials = function() {
    tools.redirect('credentials');
  };

  $scope.redirectToDates = function() {
    tools.redirect('dates');
  };

  $scope.redirectForSideMenuComponent = function() {
    if(!(tools.getLength(model.longTermBasket)===0 || $scope.isSpecificSale)) {
      if($scope.isUpdateForEmp) {
        var params=$scope.ignoreZeroQuantities($scope.createReservationParams([],[],{},true),true);
        $scope.setLeavePageBeforeUnloadTo(false);
        webservice.exclusiveCall('updateForEmp',params,script,model).then(function() {
          $scope.resetModel();
          $scope.navigateToRetrieveForEmp();
        });
      } else {
        $scope.redirectToCredentials();
      }
    }
  };

  $scope.resetModel = function() {
    model.created=tools.today();
    if($scope.isSiteMode) {
      if($scope.skipDatesPage) {
        model.startdate=new Date();
        model.page='activities';
      } else {
        model.startdate=undefined;
        model.page='dates';
      }
      model.enddate=undefined;
    } else {
      model.page='languages';
      model.startdate=new Date();
      model.enddate=new Date();
    }
    model.startdateISO=tools.dateFromJava(model.startdate);
    model.enddateISO=tools.dateFromJava(model.enddate);
    model.predateSelectorId=null;
    model.predateSelector=null;
    model.longTermBasket=[];
    model.detailsPageBasket=null;
    model.detailsPageCollections=null;
    model.fca='';
    model.days=[];
    model.voucherCodes=[];
    model.acceptTermsAndConditions=false;
    model.ctepVisit=null;
    model.ctepVisitAmount=0.0;
    model.takeawayHrs=null;
    model.multipleDays=$scope.isMultipleDays();
    model.multipleDaysDatepicker=$scope.isMultipleDaysDatepicker();
    $scope.loaded.then(function() {
      if(model.predateSelectorId===null && $scope.predateSelectors!==null && tools.getLength($scope.predateSelectors)>1 && tools.getLength(tools.match($scope.predateSelectors,{'default':true}))>0) {
        model.predateSelectorId=tools.first(tools.match($scope.predateSelectors,{'default':true})).id;
        model.predateSelector=$scope.predateSelectors[model.predateSelectorId];
      } else if($scope.predateSelectors!==null && tools.getLength($scope.predateSelectors)===1) {
        model.predateSelectorId=tools.first($scope.predateSelectors).id;
        model.predateSelector=$scope.predateSelectors[model.predateSelectorId];
      } else if(!(angular.isDefined(model.predateSelectorId) && $scope.predateSelectors!==null && model.predateSelectorId in $scope.predateSelectors)) {
        model.predateSelectorId=null;
      }
      model.multipleDaysDatepicker=$scope.isMultipleDaysDatepicker();
      model.multipleDays=$scope.isMultipleDays();
      model.delivery=$scope.isDeliverable()?null:'0';
    });
    tools.initAttributes(model,{
      'contacts':{},
      'contactexternalids':{},
      'contactcreditcards':{},
      'visits':{},
      'activities':{},
      'participants':{},
      'meals':{},
      'dishes':{},
      'hotelreservationcategories':{},
      'hotelreservationproducts':{},
      'products':{},
      'packages':{},
      'items':{},
      'formbuttons':{},
      'formbuttoncomponents':{},
      'specificSaleFormbuttonId':'',
      'specificSaleFormbutton':null,
      'specificSaleFormbuttoncomponents':[],
      'onlinepaymentState':'initial',
      'hraPerHotel':null
    });
    modelstore.cleanModel(['contactcreditcards','activities','participants','meals','dishes','hotelreservationcategories','hotelreservationproducts','products','items'],model);
    if(!$scope.isUpdateForEmp) {
      modelstore.cleanModel(['contacts','contactexternalids','visits'],model);
      model.visit={
        'id':'-1',
        'allowOwningcontactIsCompany':true,
        'owningcontactIsCompany':false
      };
      modelstore.addRecord('visits',model.visit,model);
    }
    model.personContact={
      'id':tools.getMinIdMinusOne(tools.toArray(model.contacts)),
      'firstname':$scope.stateparams.contact.firstname || '',
      'name':$scope.stateparams.contact.name || '',
      'gsm':$scope.stateparams.contact.gsm || '',
      'email':$scope.stateparams.contact.email || '',
      'country':$scope.stateparams.contact.country || parameters.countries[model.country || 'BE'].id,
      'countryCallingCode':$scope.stateparams.contact.countryCallingCode || tools.first(parameters.countries[model.country || 'BE'].countryCallingCodes),
      'number':$scope.stateparams.contact.number || '',
      'address1':$scope.stateparams.contact.address1 || '',
      'postalcode':$scope.stateparams.contact.postalcode || '',
      'address2':$scope.stateparams.contact.address2 || '',
      'agreestomarketing':'0',
      'iscompany':'0',
      'firstnameEditable':!('firstname' in $scope.stateparams.contact && $scope.stateparams.contact.firstname!==''),
      'nameEditable':!('name' in $scope.stateparams.contact && $scope.stateparams.contact.name!==''),
      'emailEditable':!('email' in $scope.stateparams.contact && $scope.stateparams.contact.email!=='')
    };
    model.personContact.countryCallingKey=model.personContact.country+model.personContact.countryCallingCode;
    modelstore.addRecord('contacts',model.personContact,model);
    model.companyContact={
      'id':tools.getMinIdMinusOne(tools.toArray(model.contacts)),
      'name':$scope.stateparams.company.name || '',
      'email':$scope.stateparams.company.email || '',
      'address1':$scope.stateparams.company.address1 || '',
      'postalcode':$scope.stateparams.company.postalcode || '',
      'address2':$scope.stateparams.company.address2 || '',
      'iscompany':'1',
      'vatnumber':$scope.stateparams.company.vatnumber || '',
      'agreestomarketing':'0'
    };
    if(model.companyContact.name!=='' || model.companyContact.vatnumber!=='' || $scope.owningcontactIsCompany) {
      model.visit.owningcontactIsCompany=true;
    }
    modelstore.addRecord('contacts',model.companyContact,model);
    if(angular.isDefined($scope.stateparams.contactexternalid.externalid) && angular.isDefined($scope.stateparams.contactexternalid.partner) && tools.getLength(tools.match(model.contactexternalids,{'externalid':$scope.stateparams.contactexternalid.externalid,'partner':$scope.stateparams.contactexternalid.partner}))===0) {
      model.visit.allowOwningcontactIsCompany=false;
      var contactexternalid={
        'id':tools.getMinIdMinusOne(tools.toArray(model.contactexternalids)),
        'contact':model.personContact.id,
        'partner':$scope.stateparams.contactexternalid.partner,
        'externalid':$scope.stateparams.contactexternalid.externalid
      };
      modelstore.addRecord('contactexternalids',contactexternalid,model);
    }
    if(tools.getLength(model.contactexternalids)>0) {
      $scope.setLeavePageBeforeUnloadTo(true);
    }
    model.contactcreditcard={
      'id':tools.getMinIdMinusOne(tools.toArray(model.contactcreditcards)),
      'unencryptedCardnumber':'',
      'unencryptedCvc':'',
      'expirymonth':'',
      'expiryyear':''
    };
    modelstore.addRecord('contactcreditcards',model.contactcreditcard,model);
    $scope.initHardcodedFormbuttoncomponents();
  };

  $scope.initHardcodedFormbuttoncomponents = function() {
    modelstore.addRecord('formbuttoncomponents',{
      'id':'parking',
      'param1':'AC',
      'param2':'PRKTIC',
      'labelen':'Parking',
      'labelnl':'Parking',
      'labelfr':'Parking',
      'hrs':'09:00:00',
      'quantity':'0',
      'adultcount':'0',
      'childcount':'0'
    },model);
    modelstore.addRecord('formbuttoncomponents',{
      'id':'voucher',
      'param1':'PR',
      'param2':'VOU',
      'quantity':'0',
      'adultcount':'0',
      'childcount':'0'
    },model);
    modelstore.addRecord('formbuttoncomponents',{
      'id':'pack',
      'param1':'PA',
      'param2':'PAC',
      'quantity':'0',
      'adultcount':'0',
      'childcount':'0'
    },model);
  };

  var availableFromWording = {'en':'Available','nl':'Beschikbaar','fr':'Disponible'};
  var fullyBookedWording = {'en':'Fully booked.','nl':'Volzet.','fr':'Complet.'};
  var daysInAdvanceWordingA = {'en':'Book at least','nl':'Reserveer ten minste','fr':'Reservez au moins'};
  var daysInAdvanceWordingB = {'en':'day in advance.','nl':'dag op voorhand.','fr':'jour avant.'};
  var daysInAdvanceWordingC = {'en':'days in advance.','nl':'dagen op voorhand.','fr':'jours avant.'};
  var beforeNowWording = {'en':'Today not available anymore.','nl':'Vandaag niet meer mogelijk.','fr':'Plus disponible aujourd\u0027hui.'};
  var accomWording = {'en':'Please select multiple days.','nl':'Gelieve meerdere dagen te selecteren.','fr':'Veuillez s\u00E9lectionner plusieurs dates'};
  var defaultWording = {'en':'Not available.','nl':'Niet beschikbaar.','fr':'Pas disponible.'};
  $scope.getNotAvailableMessage = function(formbutton) {
    var result={'wording':'','modifyDates':false};
    var availableFromMessage='';
    if(angular.isDefined(formbutton.nextOpenDay) && formbutton.nextOpenDay!==false) {
      var dateOutLong=tools.dateOutLong(formbutton.nextOpenDay);
      availableFromMessage=tools.lang(availableFromWording)+' '+dateOutLong.substr(0,1).toLowerCase()+dateOutLong.substr(1);
    }
    if(formbutton.open) {
      result.wording=tools.lang(fullyBookedWording)+' '+availableFromMessage;
    } else if(formbutton.notEnoughDaysInAdvance) {
      result.wording=tools.lang(daysInAdvanceWordingA)+' '+formbutton.daysInAdvance+' '+(formbutton.daysInAdvance<=1?tools.lang(daysInAdvanceWordingB):tools.lang(daysInAdvanceWordingC));
    } else if(formbutton.todayBeforeNow) {
      result.wording=tools.lang(beforeNowWording);
    } else if(('closedmessage'+user.lang) in formbutton && formbutton['closedmessage'+user.lang]!=='') {
      result.wording=formbutton['closedmessage'+user.lang]+(formbutton.showavailablefrom==='1'?' '+availableFromMessage:'');
    } else if(availableFromMessage!=='') {
      result.wording=availableFromMessage;
    } else if(formbutton.isAccommodation && (model.enddateISO==='' || model.startdateISO===model.enddateISO)) {
      result.wording=tools.lang(accomWording);
      result.modifyDates=true;
    } else {
      result.wording=tools.lang(defaultWording);
    }
    return result;
  };

  $scope.formbuttoncomponentReferenceArrayToRecords = function(formbuttoncomponentReferenceArray,param1,applicableModel,collection) {
    return formbuttoncomponentReferenceArray.filter(function(formbuttoncomponentReference) {
      return model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param1===param1;
    }).map(function(formbuttoncomponentReference) {
      return applicableModel[collection][formbuttoncomponentReference.record];
    });
  };

  $scope.getEventsAndProducts = function(basket,localModel) {
    var activities=$scope.formbuttoncomponentReferenceArrayToRecords(basket,'AC',localModel,'activities');
    var participants=tools.join(activities,'participants',localModel);
    var meals=$scope.formbuttoncomponentReferenceArrayToRecords(basket,'ME',localModel,'meals');
    var dishes=tools.join(meals,'dishes',localModel);
    var hotelreservationcategories=$scope.formbuttoncomponentReferenceArrayToRecords(basket,'HS',localModel,'hotelreservationcategories');
    var hotelreservationproducts=tools.join(hotelreservationcategories,'hotelreservationproducts',localModel);
    var products=$scope.formbuttoncomponentReferenceArrayToRecords(basket,'PR',localModel,'products');
    var packages=$scope.formbuttoncomponentReferenceArrayToRecords(basket,'PA',localModel,'packages');
    return {
      'activities':activities,
      'participants':participants,
      'meals':meals,
      'dishes':dishes,
      'hotelreservationcategories':hotelreservationcategories,
      'hotelreservationproducts':hotelreservationproducts,
      'products':products,
      'packages':packages,
      'items':tools.inverseJoin(participants,'items','item',localModel)
        .concat(tools.inverseJoin(dishes,'items','item',localModel))
        .concat(tools.inverseJoin(hotelreservationcategories,'items','item',localModel))
        .concat(tools.inverseJoin(hotelreservationproducts,'items','item',localModel))
        .concat(tools.inverseJoin(products,'items','item',localModel))
    };
  };

  $scope.excludeFormbuttoncomponentreference = function(basket,formbuttoncomponentReference) {
    var i,j,basketContent,formbuttoncomponentRef;
    for(i in basket) {
      basketContent=basket[i];
      for(j in basketContent) {
        formbuttoncomponentRef=basketContent[j];
        if(formbuttoncomponentRef.formbuttoncomponent===formbuttoncomponentReference.formbuttoncomponent && formbuttoncomponentRef.record===formbuttoncomponentReference.record) {
          basketContent.splice(j,1);
          break;
        }
      }
    }
  };

  $scope.updateRecordsFromLongTermBasket = function(shortTermBasket) {
    var i,j,oldShortTermBasket,formbuttoncomponentReference,formbuttoncomponentReferenceExists,isOldShortTermBasket;
    var oldLongTermBasket=tools.clone(model.longTermBasket);
    var newShortTermBasket=tools.clone(shortTermBasket);
    var firstFormbuttoncomponentReference=tools.first(newShortTermBasket);
    var index=-1;
    for(i in oldLongTermBasket) {
      oldShortTermBasket=oldLongTermBasket[i];
      isOldShortTermBasket=tools.match(oldShortTermBasket,{'formbuttoncomponent':firstFormbuttoncomponentReference.formbuttoncomponent,'record':firstFormbuttoncomponentReference.record}).length>0;
      if(isOldShortTermBasket) {
        index=i;
        for(j in oldShortTermBasket) {
          formbuttoncomponentReference=oldShortTermBasket[j];
          formbuttoncomponentReferenceExists=tools.match(newShortTermBasket,{'formbuttoncomponent':formbuttoncomponentReference.formbuttoncomponent,'record':formbuttoncomponentReference.record}).length>0;
          if(!formbuttoncomponentReferenceExists) {
            $scope.excludeFormbuttoncomponentreference([model.longTermBasket[i]],formbuttoncomponentReference);
            $scope.deleteFormbuttoncomponentReference(formbuttoncomponentReference);
          }
        }
        for(j in newShortTermBasket) {
          formbuttoncomponentReference=newShortTermBasket[j];
          formbuttoncomponentReference.created=true;
          formbuttoncomponentReferenceExists=tools.match(oldShortTermBasket,{'formbuttoncomponent':formbuttoncomponentReference.formbuttoncomponent,'record':formbuttoncomponentReference.record}).length>0;
          if(!formbuttoncomponentReferenceExists) {
            model.longTermBasket[i].push(formbuttoncomponentReference);
          }
        }
        break;
      }
    }
    if(index<0) {
      model.longTermBasket.push(newShortTermBasket);
    }
  };

  $scope.addRecordsFromShortTermBasket = function(shortTermBasket,collections,addToCart) {
    var i,j,formbuttoncomponentReference,formbuttoncomponent,hotelreservationcategory,hotelreservationproducts,hotelreservationproduct,collectionName,collection,record;
    var tempShortTermBasket=tools.clone(shortTermBasket);
    for(i in shortTermBasket) {
      formbuttoncomponentReference=shortTermBasket[i];
      if(angular.isObject(formbuttoncomponentReference) && ('record' in formbuttoncomponentReference)) {
        formbuttoncomponent=model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent];
        if(formbuttoncomponent.param1==='HS' && (formbuttoncomponentReference.record in model.hotelreservationcategories)) {
          hotelreservationcategory=model.hotelreservationcategories[formbuttoncomponentReference.record];
          hotelreservationproducts=tools.join([hotelreservationcategory],'hotelreservationproducts',model);
          for(j in hotelreservationproducts) {
            hotelreservationproduct=hotelreservationproducts[j];
            if(tools.match(collections.items,{'id':hotelreservationproduct.item}).length===0) {
              modelstore.removeRecord('items',hotelreservationproduct.item,model);
            }
            if(tools.match(collections.hotelreservationproducts,{'id':hotelreservationproduct.id}).length===0) {
              modelstore.removeRecord('hotelreservationproducts',hotelreservationproduct.id,model);
            }
          }
          if(tools.match(collections.items,{'id':hotelreservationcategory.item}).length===0) {
            modelstore.removeRecord('items',hotelreservationcategory.item,model);
          }
          if(tools.match(collections.hotelreservationcategories,{'id':hotelreservationcategory.id}).length===0) {
            modelstore.removeRecord('hotelreservationcategories',hotelreservationcategory.id,model);
          }
        }
      }
    }
    $scope.shortTermBasket=[];
    for(collectionName in collections) {
      collection=collections[collectionName];
      for(i in collection) {
        record=collection[i];
        modelstore.addRecord(collectionName,record,model);
      }
    }
    if(addToCart) {
      var toGTM = [];
      var items=$scope.getItems(tempShortTermBasket);
      for(var id in items) {
        toGTM.push(model.items[items[id].id]);
      }
      $scope.pushGTMAddToCart(toGTM);
    }
  };

  $scope.basketContentIsPack = function(basketcontent) {
    return basketcontent.reduce(function(isPack,formbuttoncomponentReference) {
      var formbuttoncomponent=model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent];
      return isPack || angular.isDefined(formbuttoncomponent) && angular.isDefined(model.formbuttons[formbuttoncomponent.formbutton]) && model.formbuttons[formbuttoncomponent.formbutton].singlequantity==='1';
    },false);
  };

  $scope.scrollToId = function(id) {
    $location.hash(id);
    $anchorScroll();
  };

  $scope.navigateToRetrieveForEmp = function() {
    $window.location=model.employeeAppUrl+'#!/'+user.lang+'/activities/retrieveForEmp/V'+model.visit.id;
  };

  $scope.afterLoad = function() {
    var i,modelVarsToBind,modelVarBeingBound;
    if($scope.isSpecificSale) {
      if(!(model.specificSaleFormbuttonId in model.formbuttons)) {
        errorlog.error(tools.lang({
          'en':'Invalid button',
          'nl':'Ongeldige knop',
          'fr':'Invalid button'
        }));
      }
      model.specificSaleFormbutton=model.formbuttons[model.specificSaleFormbuttonId];
      var packId='pack'+model.specificSaleFormbuttonId;
      if(model.specificSaleFormbutton.singlequantity==='1' && angular.isUndefined(model.formbuttoncomponents[packId])) {
        modelstore.addRecord('formbuttoncomponents',{
          'id':packId,
          'formbutton':model.specificSaleFormbuttonId,
          'sort':'0',
          'labelen':model.specificSaleFormbutton.labelen,
          'labelnl':model.specificSaleFormbutton.labelnl,
          'labelfr':model.specificSaleFormbutton.labelfr,
          'quantitylabelen':model.specificSaleFormbutton.quantitylabelen,
          'quantitylabelnl':model.specificSaleFormbutton.quantitylabelnl,
          'quantitylabelfr':model.specificSaleFormbutton.quantitylabelfr,
          'param1':'PA',
          'param2':'PAC',
          'param3':'',
          'dayoffset':'0',
          'hrs':'',
          'quantitymultiplier':'FIXED',
          'quantity':'0',
          'minpackquantity':model.specificSaleFormbutton.minpackquantity,
          'adultcountbehaviour':model.specificSaleFormbutton.adultcountbehaviour,
          'adultcount':model.specificSaleFormbutton.adultcount,
          'childcountbehaviour':model.specificSaleFormbutton.childcountbehaviour,
          'childcount':model.specificSaleFormbutton.childcount,
          'heatmap':'0',
          'unitprice':'0'
        },model);
      }
      model.specificSaleFormbuttoncomponents=tools.join(model.specificSaleFormbutton,'formbuttoncomponents',model);
    }

    if($scope.isSiteMode) {

      $scope.resetModel();

      if($scope.isSpecificSale) {
        model.multipleDays=(model.specificSaleFormbutton.singlequantity==='0' && model.specificSaleFormbuttoncomponents.reduce(function(acc,formbuttoncomponent) {
          return acc || formbuttoncomponent.param1==='HS';
        },false))?'1':'0';

        if(model.specificSaleFormbutton.hasfixeddate==='1') {
          model.startdate=model.specificSaleFormbutton.fixedstartdate;
          model.enddate=model.specificSaleFormbutton.fixedenddate;
          $scope.onDateChange('startdate',model.startdate.getTime());
          $scope.skipDatesPage=true;
        } else {
          $scope.skipDatesPage=model.specificSaleFormbuttoncomponents.reduce(function(acc,formbuttoncomponent) {
            return acc && formbuttoncomponent.param1==='PR';
          },true);
          if($scope.skipDatesPage) {
            model.startdate=new Date();
            model.enddate=new Date();
            $scope.onDateChange('startdate',model.startdate.getTime());
          }
        }
        if(model.onlinepaymentState==='success') {
          $scope.redirectWhenFinished();
        } else if($scope.skipDatesPage) {
          $scope.redirectToActivities();
        } else {
          tools.redirect('dates');
        }
      } else {
        // localstorage
        if($scope.isUpdateForEmp) {
          localStorageService.clear();
        }
        modelVarsToBind=['contacts','created','page','startdate','enddate','predateSelectorId','multipleDays','longTermBasket','visit','dateofbirth','activities','participants','meals','dishes','hotelreservationcategories','hotelreservationproducts','products','items','hraPerHotel','currentStepIndex','fca'];
        for(i in modelVarsToBind) {
          modelVarBeingBound=modelVarsToBind[i];
          localStorageService.bind($scope,['model',modelVarBeingBound],'outdoor_'+$scope.ffo+'_'+modelVarBeingBound,model[modelVarBeingBound]);
        }
        if(model.created===tools.today()) {
          model.startdate=formatting.dateIn(model.startdate);
          model.enddate=formatting.dateIn(model.enddate);
          model.dateofbirth=formatting.dateIn(model.dateofbirth);
          modelstore.addRecord('visits',model.visit,model);
          angular.forEach(['activities','participants','meals','dishes','hotelreservationcategories','hotelreservationproducts','products','items'],function(tableName) {
            for(i in model[tableName]) {
              modelstore.addRecord(tableName,model[tableName][i],model);
            }
          });
        } else {
          $scope.resetModel();
        }
        // if('predateSelector' in model) {
        //   model.startdate=tools.dateToJava($scope.model.predateSelector.startdate);
        //   model.enddate=tools.dateToJava($scope.model.predateSelector.enddate);
        //   if($scope.model.predateSelector.startdate!==$scope.model.predateSelector.enddate) {
        //     model.multipleDays='1';
        //   }
        //   model.hraPerHotel=$scope.model.predateSelector.hraPerHotel;
        //   model.page='activities';
        // }
        if(model.startdate!==null && angular.isDefined(model.startdate)) {
          $scope.onDateChange('startdate',model.startdate.getTime());
        }
        if(model.onlinepaymentState==='success') {
          $scope.redirectWhenFinished();
        } else {
          tools.redirect(model.page);
        }
      }

      if(model.onlinepaymentState==='failure') {
        $scope.dismissModal();
        $scope.modal=$uibModal.open({
          'templateUrl':'app/views/failureModal.html',
          'scope':$scope,
          'backdrop':'static',
          'keyboard':false
        });
        $scope.modal.result.then(function() {
          $scope.modal=null;
        },function() {
          $scope.modal=null;
        });
      }
    }

    if($scope.isPartnerIdAvailable() && $scope.isUndefinedReferer()) {
      $scope.modal=$uibModal.open({
        'templateUrl':'app/views/undefinedRefererModal.html',
        'scope':$scope,
        'size':'md',
        'backdrop':'static',
        'keyboard':false
      });
      $scope.modal.result.then(function(response) {
        var contactexternalid={
          'id':tools.getMinIdMinusOne(tools.toArray(model.contactexternalids)),
          'contact':model.personContact.id,
          'partner':response.partner,
          'externalid':response.externalid
        };
        modelstore.addRecord('contactexternalids',contactexternalid,model);
      });
    }

    if($scope.isKioskMode) {

      ctep.onTerminalIsClaimed=$scope.resetKiosk;
      ctep.onTerminalIsUnavailable=$scope.resetKiosk;
      ctep.claimMyTerminal();

      // Idle
      $scope.idleModal=null;
      $scope.$on('IdleStart', function() {
        if($scope.idleModal!==null) {
          $scope.idleModal.dismiss();
        }
        $scope.idleModal=$uibModal.open({
          'templateUrl':'app/views/idleModal.html',
          'scope':$scope,
          'size':'lg',
          'backdrop':'static',
          'keyboard':false
        });
        $scope.idleModal.result.then(function() {
          $scope.dismissModal();
          $scope.resetKiosk();
          $scope.idleModal=null;
        },function() {
          Idle.watch();
          $scope.idleModal=null;
        });
      });
      $scope.$on('IdleTimeout', function() {
        $scope.idleModal.close();
      });

      tools.redirect('setup');
    }
  };

  var contactStateparamFields={
    'st':{'sort':0,'field':'address1','others':['nr','bn']},
    'nr':{'sort':1,'field':'address1','others':['st','bn']},
    'bn':{'sort':2,'field':'address1','others':['st','nr']},
    'pc':{'sort':0,'field':'postalcode','others':false},
    'gm':{'sort':0,'field':'address2','others':false},
    'fn':{'sort':0,'field':'firstname','others':false},
    'ln':{'sort':0,'field':'name','others':false},
    've':{'sort':0,'field':'email','others':false},
    'firstname':{'sort':0,'field':'firstname','others':false},
    'name':{'sort':0,'field':'name','others':false},
    'email':{'sort':0,'field':'email','others':false},
    'tn':{'sort':0,'field':'gsm','others':false}
  };
  var companyStateparamFields={
    'cst':{'sort':0,'field':'address1','others':['cnr','cbn']},
    'cnr':{'sort':1,'field':'address1','others':['cst','cbn']},
    'cbn':{'sort':2,'field':'address1','others':['cst','cnr']},
    'cpc':{'sort':0,'field':'postalcode','others':false},
    'cgm':{'sort':0,'field':'address2','others':false},
    'cn':{'sort':0,'field':'name','others':false},
    'ce':{'sort':0,'field':'email','others':false},
    'companyname':{'sort':0,'field':'name','others':false},
    'vat':{'sort':0,'field':'vatnumber','others':false},
    'fx':{'sort':0,'field':'fixedCompany','others':false}
  };
  var contactexternalidStateparamFields={
    'vn':{'sort':0,'field':'externalid'},
    'ex':{'sort':0,'field':'externalid'},
    'pt':{'sort':0,'field':'partner'}
  };
  var stateparamFields={
    'fb':{'field':'formbutton','removeFromLocalStorageAfterPayment':true},
    'formbutton':{'field':'formbutton','removeFromLocalStorageAfterPayment':true},
    'specific':{'field':'specificSaleFormbuttonId','removeFromLocalStorageAfterPayment':false},
    'selection':{'field':'selectionFormbuttons','removeFromLocalStorageAfterPayment':true},
    'lg':{'field':'lang','removeFromLocalStorageAfterPayment':true},
    'lang':{'field':'lang','removeFromLocalStorageAfterPayment':true},
    'promo':{'field':'promoCode','removeFromLocalStorageAfterPayment':true},
    'visitId':{'field':'visitId','removeFromLocalStorageAfterPayment':true},
    'token':{'field':'token','removeFromLocalStorageAfterPayment':true}
  };

  $scope.modal=null;
  $scope.loaded=stateService.getState().then(function() {
    return windowService.getWindowState();
  }).then(function(windowState) {
    $scope.windowIsLoading=false;
    var i,param,other,modelVarsToBind,modelVarBeingBound;
    $scope.isSiteMode=config.get('mode')==='site';
    $scope.isKioskMode=config.get('mode')==='kiosk';
    if(!config.exists('ffo')) {
      errorlog.error('ffo is not configured');
      $log.log('ffo is not configured');
    }
    $scope.ffo=config.get('ffo');
    if(user.lang===null) {
      user.lang=config.get('defaultLang');
    }

    if(windowState==='new' && stateService.state.onlinepaymentState!=='failure') {
      for(i in stateparamFields) {
        param=stateparamFields[i];
        if(param.removeFromLocalStorageAfterPayment) {
          $window.localStorage.removeItem('outdoor_stateparams_'+$scope.ffo+'_'+stateparamFields[i].field);
        }
      }
      $window.localStorage.removeItem('outdoor_stateparams_'+$scope.ffo+'_contact');
      $window.localStorage.removeItem('outdoor_stateparams_'+$scope.ffo+'_company');
      $window.localStorage.removeItem('outdoor_stateparams_'+$scope.ffo+'_contactexternalid');
    }

    var localParams=new Intl.Locale(navigator.language);
    $scope.stateparams.formbutton=stateService.state.formbutton || config.get('formbutton') || model.specificSaleFormbuttonId || null;
    if($scope.isKioskMode) {
      $scope.stateparams.specificSaleFormbuttonId=stateService.state.specificSaleFormbuttonId || model.specificSaleFormbuttonId || null;
    }
    $scope.stateparams.selectionFormbuttons=stateService.state.selectionFormbuttons || config.get('selectionFormbuttons') || null;
    $scope.stateparams.lang=stateService.state.lang || config.get('lang') || config.langs.indexOf(localParams.language)!==-1?localParams.language:null;
    $scope.stateparams.promoCode=stateService.state.promoCode || config.get('promoCode') || null;
    $scope.stateparams.visitId=stateService.state.visitId || null;
    $scope.stateparams.token=stateService.state.token || null;
    $scope.stateparams.contact={};
    $scope.stateparams.company={};
    $scope.stateparams.contactexternalid={};
    if('params' in stateService.state) {
      for(param in stateService.state.params) {
        var value=stateService.state.params[param];
        if(param in contactStateparamFields && angular.isUndefined($scope.stateparams.contact[contactStateparamFields[param].field])) {
          var contactStateparam=contactStateparamFields[param];
          if(contactStateparam.others) {
            var values={};
            values[contactStateparam.sort]=value;
            for(i in contactStateparam.others) {
              other=contactStateparam.others[i];
              if(other in stateService.state.params) {
                values[contactStateparamFields[other].sort]=stateService.state.params[other];
              }
            }
            $scope.stateparams.contact[contactStateparam.field]=Object.keys(values).sort().reduce(function(previousValue,currentValue) {
              return previousValue+(previousValue===''?'':' ')+values[currentValue];
            },'');
          } else {
            $scope.stateparams.contact[contactStateparam.field]=value;
          }
        }
        if(param in companyStateparamFields && angular.isUndefined($scope.stateparams.company[companyStateparamFields[param].field])) {
          var companyStateparam=companyStateparamFields[param];
          if(companyStateparam.others) {
            var companyValues={};
            companyValues[companyStateparam.sort]=value;
            for(i in companyStateparam.others) {
              other=companyStateparam.others[i];
              if(other in stateService.state.params) {
                companyValues[companyStateparamFields[other].sort]=stateService.state.params[other];
              }
            }
            $scope.stateparams.company[companyStateparam.field]=Object.keys(companyValues).sort().reduce(function(previousValue,currentValue) {
              return previousValue+(previousValue===''?'':' ')+companyValues[currentValue];
            },'');
          } else {
            $scope.stateparams.company[companyStateparam.field]=value;
          }
        }
        if(param in contactexternalidStateparamFields && angular.isUndefined($scope.stateparams.contactexternalid[contactexternalidStateparamFields[param].field])) {
          $scope.stateparams.contactexternalid[contactexternalidStateparamFields[param].field]=value;
        }
        if(param in stateparamFields) {
          if(stateparamFields[param].field==='selectionFormbuttons' && angular.isString(value)) {
            try {
              value=angular.fromJson(value);
            } catch(e) {
              $log.error('malformed json structure for selection');
              value=[];
            }
            if(angular.isArray(value)) {
              for(i in value) {
                value[i]=parseInt(value[i],10);
                if(!angular.isNumber(value[i])) {
                  value.splice(i,1);
                }
              }
            } else {
              value=[];
            }
          }
          $scope.stateparams[stateparamFields[param].field]=value;
        }
      }
      if('externalid' in $scope.stateparams.contactexternalid && !('partner' in $scope.stateparams.contactexternalid) && config.exists('partner')) {
        $scope.stateparams.contactexternalid.partner=config.get('partner');
      }
    }
    modelVarsToBind=['formbutton','specificSaleFormbuttonId','selectionFormbuttons','lang','promoCode','contact','company','contactexternalid','visitId','token'];
    for(i in modelVarsToBind) {
      modelVarBeingBound=modelVarsToBind[i];
      localStorageService.bind($scope,['stateparams',modelVarBeingBound],'outdoor_stateparams_'+$scope.ffo+'_'+modelVarBeingBound,$scope.stateparams[modelVarBeingBound]);
    }

    if($scope.isSiteMode && 'formbutton' in $scope.stateparams && parseInt($scope.stateparams.formbutton,10)>0) {
      model.specificSaleFormbuttonId=$scope.stateparams.formbutton;
    } else if($scope.isKioskMode && 'specificSaleFormbuttonId' in $scope.stateparams && parseInt($scope.stateparams.specificSaleFormbuttonId,10)>0) {
      model.specificSaleFormbuttonId=$scope.stateparams.specificSaleFormbuttonId;
    } else {
      model.specificSaleFormbuttonId='';
    }
    $scope.logo=config.exists('logo')?config.get('logo'):false;
    $scope.logoLandscape=config.exists('logoLandscape')?config.get('logoLandscape'):false;
    $scope.isSpecificSale=model.specificSaleFormbuttonId!=='';
    $scope.isUpdateForEmp=$scope.stateparams.visitId!==null;
    $scope.skipDatesPage=config.exists('skipDatesPage')?config.get('skipDatesPage'):false;
    $scope.owningcontactIsCompany=config.exists('owningcontactIsCompany')?config.get('owningcontactIsCompany'):false;
    model.campaignSuffix='';
    model.campaignCode='';
    model.campaignPromotion=null;
    model.campaignFrmIds=[];
    model.campaignOnlyForOnlinePayment=false;
    if(!$scope.isSpecificSale && 'selectionFormbuttons' in $scope.stateparams && angular.isArray($scope.stateparams.selectionFormbuttons)) {
      model.selectionFormbuttons=$scope.stateparams.selectionFormbuttons;
      $scope.selectionFormbuttonsFca={'id':$scope.ffo+'***','field1':-1};
    } else {
      model.selectionFormbuttons=[];
    }
    if($scope.isSiteMode) {
      if($scope.stateparams.lang!==null) {
        user.lang=$scope.stateparams.lang;
      }
      if($scope.stateparams.promoCode!==null) {
        model.campaignSuffix=$scope.stateparams.promoCode;
      }
    }
    $scope.termsAndConditionsLinks=config.exists('termsAndConditionsLinks')?config.get('termsAndConditionsLinks'):[];
    $scope.privacyLink=config.exists('privacyLink')?config.get('privacyLink'):null;
    $scope.proposeVoucherPayment=config.exists('proposeVoucherPayment')?config.get('proposeVoucherPayment'):false;
    $scope.onlineDiscountMessage=config.exists('onlineDiscountMessage')?config.get('onlineDiscountMessage'):null;
    $scope.predateSelectors=config.exists('predateSelectors')?config.get('predateSelectors'):null;
    return $scope.load();
  }).then(function(response) {
    var defered=$q.defer();
    if(response) {
      $scope.afterLoad();
      $scope.$watch('model.currentStepIndex', function() {
        if(angular.isDefined(model.currentStepIndex)) {
          if(model.currentStepIndex===0 && !$scope.skipDatesPage) {
            $scope.redirectToDates();
          } else if(model.currentStepIndex>=tools.getLength($scope.getFilteredFca())+1) {
            if(tools.getLength(model.longTermBasket)>0) {
              if($scope.isSiteMode) {
                $scope.openParkingQuestionModal().then(function() {
                  $scope.redirectToCredentials();
                });
              } else {
                $scope.redirectToCredentials();
              }
            } else {
              $scope.decrementCurrentStepIndex();
            }
          } else {
            var filteredFca=$scope.getFilteredFca().sort(function(a,b) {
              return tools.numberIn(a.field1)-tools.numberIn(b.field1);
            });
            if(angular.isDefined(filteredFca[model.currentStepIndex-1])) {
              model.fca = filteredFca[model.currentStepIndex-1].id;
              $scope.redirectToActivities();
            } else if(model.fca==='' && model.currentStepIndex>0 && tools.getLength(model.longTermBasket)>0) {
              $scope.redirectToCredentials();
            } else if(model.fca!=='' && model.currentStepIndex>0) {
              $scope.redirectToActivities();
            } else {
              $scope.redirectToStart();
            }
          }
        }
      });
      defered.resolve(true);
    } else {
      defered.reject(false);
    }
    return defered.promise;
  });

  $scope.openDetailsModal = function(type) {
    var result;
    if($scope.shortTermBasket.length>0) {
      if(type==='edit') {
        var collections=$scope.getEventsAndProducts($scope.shortTermBasket,model);
        var i,collectionName,collection,record;
        model.backupCollections={};
        for(collectionName in collections) {
          model.backupCollections[collectionName]={};
          collection=collections[collectionName];
          for(i in collection) {
            record=collection[i];
            model.backupCollections[collectionName][record.id]=tools.clone(record);
          }
        }
      }
      $scope.dismissModal();
      $scope.modal=$uibModal.open({
        'templateUrl':'app/views/detailsModal.html',
        'scope':$scope,
        'size':$scope.isSiteMode?'md':'lg',
        'backdrop':'static',
        'keyboard':false
      });
      result=$scope.modal.result.then(function(response) {
        $scope.modal=null;
        $scope.shortTermBasket=[];
        $scope.updateRecordsFromLongTermBasket(response.shortTermBasket);
        $scope.addRecordsFromShortTermBasket(response.shortTermBasket,response.collections,true);
        $scope.trustAsHtml();
        return response;
      },function() {
        $scope.modal=null;
        $scope.shortTermBasket=[];
        var i,collectionName,collection,record;
        if(type==='edit') {
          for(collectionName in model.backupCollections) {
            collection=model.backupCollections[collectionName];
            for(i in collection) {
              record=collection[i];
              modelstore.addRecord(collectionName,record,model);
            }
            model.backupCollections[collectionName]={};
          }
        }
      });
    } else {
      result=$q.reject();
    }
    return result;
  };

  $scope.openBuyParkingModal = function() {
    var result;
    if('PRKTIC' in $scope.getActivityAvailabilities() && $scope.getActivityAvailabilities().PRKTIC.available) {
      $scope.shortTermBasket=[{'formbuttoncomponent':'parking'}];
      result=$scope.openDetailsModal().then(function() {
        return $q.resolve();
      });
    } else {
      result=$q.reject();
    }
    return result;
  };

  $scope.openParkingQuestionModal = function() {
    var parkingResult1,activity;
    if(parameters.FFO[$scope.ffo].field5==='1') {
      var activities=[].concat.apply([],model.longTermBasket).filter(function(formbuttoncomponentReference) {
        return formbuttoncomponentReference.formbuttoncomponent in model.formbuttoncomponents && model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param1==='AC';
      }).map(function(formbuttoncomponentReference) {
        return model.activities[formbuttoncomponentReference.record];
      });
      var activityNeedsParking=false;
      for(var i in activities) {
        activity=activities[i];
        if(activity.frm in parameters.FRM && parameters.FRM[activity.frm].field11==='1') {
          activityNeedsParking=true;
          break;
        }
      }
      var hotelreservationcategories=[].concat.apply([],model.longTermBasket).filter(function(formbuttoncomponentReference) {
        return formbuttoncomponentReference.formbuttoncomponent in model.formbuttoncomponents && model.formbuttoncomponents[formbuttoncomponentReference.formbuttoncomponent].param1==='HS';
      }).map(function(formbuttoncomponentReference) {
        return model.hotelreservationcategories[formbuttoncomponentReference.record];
      });
      if(activities.length>0 && tools.sum(tools.inverseJoin(tools.join(tools.match(activities,{'frm':'PRKTIC'}),'participants',model),'items','item',model),'quantity')===0.0 &&
          hotelreservationcategories.length===0 && activityNeedsParking) {
        $scope.dismissModal();
        $scope.modal=$uibModal.open({
          'templateUrl':'app/views/parkingQuestionModal.html',
          'scope':$scope,
          'size':'lg',
          'backdrop':'static',
          'keyboard':false
        });
        parkingResult1=$scope.modal.result.then(function(needsParking) {
          var parkingResult2;
          if(needsParking) {
            parkingResult2=$scope.openBuyParkingModal();
          } else {
            parkingResult2=$q.resolve();
          }
          return parkingResult2;
        });
      } else {
        parkingResult1=$q.resolve();
      }
    } else {
      parkingResult1=$q.resolve();
    }
    return parkingResult1;
  };

  $scope.decrementCurrentStepIndex = function() {
    if(angular.isUndefined(model.currentStepIndex)) {
      model.currentStepIndex=$scope.skipDatesPage?1:0;
    } else if((model.currentStepIndex>0 && !$scope.skipDatesPage) || (model.currentStepIndex>1 && $scope.skipDatesPage)) {
      if(model.currentStepIndex-1===0) {
        model.fca='';
      }
      model.currentStepIndex--;
    }
  };
  $scope.incrementCurrentStepIndex = function() {
    if(angular.isUndefined(model.currentStepIndex)) {
      model.currentStepIndex=$scope.skipDatesPage?2:1;
    } else if(model.currentStepIndex<tools.getLength($scope.getFilteredFca()) + 1) {
      if(model.currentStepIndex+1===tools.getLength($scope.getFilteredFca())+1) {
        model.fca='';
      }
      model.currentStepIndex++;
    }
  };
  $scope.setCurrentStepIndexToMin = function() {
    model.fca='';
    model.currentStepIndex=$scope.skipDatesPage?1:0;
  };
  $scope.setCurrentStepIndexToMax = function() {
    model.fca='';
    model.currentStepIndex=tools.getLength($scope.getFilteredFca()) + 1;
  };

  $scope.isUndefinedReferer = function() {
    return $scope.isPartnerIdAvailable() && (tools.getLength(model.contactexternalids)===0 || angular.isUndefined(tools.first(model.contactexternalids).externalid));
  };

  $scope.getGTMProducts = function(items) {
    var item,product,itemType;
    var params={'AC':'activities','ME':'meals','HS':'hotelreservationcategories','HP':'hotelreservationproducts','PR':'products'};
    var products=[];
    for(var id in items) {
      item=items[id];
      if(angular.isDefined(item) && 'itm' in item) {
        itemType=item.itm.substr(0,2);
        product={};
        product.category=params[itemType];
        product.name=item.itm;
        if(itemType==='AC' && angular.isDefined(parameters.ACT[item.itm.substr(2,3)])) {
          product.description=parameters.ACT[item.itm.substr(2,3)].labelen;
        } else if(itemType==='ME' && angular.isDefined(parameters.DSH[item.itm.substr(2,6)])) {
          product.description=parameters.DSH[item.itm.substr(2,6)].labelen;
        } else if(itemType==='HS' && angular.isDefined(parameters.HSC[item.itm.substr(2,6)])) {
          product.description=parameters.HSC[item.itm.substr(2,6)].labelen;
        } else if(itemType==='HP' && angular.isDefined(parameters.HPD[item.itm.substr(2,6)])) {
          product.description=parameters.HPD[item.itm.substr(2,6)].labelen;
        } else if(itemType==='PR' && angular.isDefined(parameters.PRD[item.itm.substr(5,3)])) {
          product.description=parameters.PRD[item.itm.substr(5,3)].labelen;
        } else {
          product.description='';
        }
        if(itemType==='PR' && item.itm.substr(5,3)==='VOU' && item.grossunitprice===1) {
          product.price=item.quantity;
          product.quantity=item.grossunitprice;
        } else {
          product.price=item.grossunitprice;
          product.quantity=item.quantity;
        }
        products.push(product);
      }
    }
    return products;
  };

  $scope.pushGTMPurchase = function() {
    if(angular.isDefined($window.dataLayer)) {
      var items=$scope.filterItemsForGTM(model.items);
      var products=$scope.getGTMProducts(items);
      $window.dataLayer.push({
        'event':'transaction',
        'ecommerce':{
          'purchase':{
            'actionField':{
              'id':(new Date()).getTime(),
              'affiliation':parameters.FFO[config.get('ffo')].labelen,
              'revenue':tools.sum(items,'nettopay'),
              'tax':'0',
              'shipping':'0',
              'coupon':''
            },
            'products':products
          }
        },
        'enhancedConversionData':{
          'email':model.personContact.email.trim().toLowerCase(),
          'phone':model.personContact.gsm.trim().toLowerCase().replaceAll(/(\(0\)|[^\d\+])/g,'')
        }
      });
    }
  };

  $scope.filterItemsForGTM = function(items) {
    return Object.values(Object.values(items).filter(function(item) {
      return tools.amountIn(item.quantity)!==0 && ('grossunitprice' in item);
    }));
  };

  $scope.pushGTMCheckout = function(items) {
    if(angular.isDefined($window.dataLayer)) {
      items=$scope.filterItemsForGTM(model.items);
      var products=$scope.getGTMProducts(items);
      $window.dataLayer.push({
        'event':'checkout',
        'ecommerce':{
          'checkout':{
            'value':tools.sum(items,'nettopay'),
            'actionField':{'step':1},
            'products':products
          }
        }
      });
    }
  };

  $scope.pushGTMAddToCart = function(items) {
    if(angular.isDefined($window.dataLayer) && items.length>0) {
      var products=$scope.getGTMProducts(items);
      $window.dataLayer.push({
        'event':'addToCart',
        'ecommerce':{
          'currencyCode':'EUR',
          'add':{
            'products':products
          }
        }
      });
    }
  };

  $scope.pushGTMRemoveFromCart = function(items) {
    if(angular.isDefined($window.dataLayer) && items.length>0) {
      var products=$scope.getGTMProducts(items);
      $window.dataLayer.push({
        'event':'removeFromCart',
        'ecommerce':{
          'remove':{
            'products':products
          }
        }
      });
    }
  };
}]);
