angular.module('app', ['ngAnimate','ui.router','ui.bootstrap','ui.scrollpoint','toastr','ngWebSocket','ngTouch','ngIdle','bc.AngularKeypad','angularRipple']);

angular.module('app').config(['$qProvider', function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
}]);

angular.module('app').config(['toastrConfig',function(toastrConfig) {
  angular.extend(toastrConfig, {
    'positionClass':'toast-top-full-width',
    'timeout':'7000'
  });
}]);

angular.module('app').config(['IdleProvider', function (IdleProvider) {
  IdleProvider.idle(20); // in seconds
  IdleProvider.timeout(20); // in seconds
  IdleProvider.autoResume('notIdle');
}]);

/** @ngInject */
angular.module('app').config(['$stateProvider','$urlRouterProvider',function($stateProvider, $urlRouterProvider) {
  // $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('app', {
      url: '/',
      template: ' ',
      controller:['stateService','windowService','config',function(stateService,windowService,config) {
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('finished', {
      url: '/finished/:state',
      template: ' ',
      controller:['$scope','$stateParams','stateService','windowService','config',function($scope,$stateParams,stateService,windowService,config) {
        $scope.model.onlinepaymentState=$stateParams.state;
        stateService.setState({'onlinepaymentState':$stateParams.state});
        windowService.setWindowState(config);
      }]
    })
    .state('train', {
      url: '/train',
      template: ' ',
      controller:['$scope','stateService','windowService','config',function($scope,stateService,windowService,config) {
        $scope.model.specificSaleFormbuttonId='5';
        stateService.setState({'specificSaleFormbuttonId':'5'});
        windowService.setWindowState(config);
      }]
    })
    .state('kayak', {
      url: '/kayak',
      template: ' ',
      controller:['$scope','stateService','windowService','config',function($scope,stateService,windowService,config) {
        $scope.model.specificSaleFormbuttonId='67';
        stateService.setState({'specificSaleFormbuttonId':'67'});
        windowService.setWindowState(config);
      }]
    })
    .state('singleproduct', {
      url: '/singleproduct/:formbuttonId',
      template: ' ',
      controller:['$stateParams','stateService','windowService','config',function($stateParams,stateService,windowService,config) {
        config.set('formbutton',$stateParams.formbuttonId);
        stateService.setState({'formbutton':$stateParams.formbuttonId});
        windowService.setWindowState(config);
      }]
    })
    .state('promo', {
      url: '/promoCode/:promoCode',
      template: ' ',
      controller:['$stateParams','stateService','windowService','config',function($stateParams,stateService,windowService,config) {
        config.set('promoCode',$stateParams.promoCode);
        stateService.setState({'promoCode':$stateParams.promoCode});
        windowService.setWindowState(config);
      }]
    })
    .state('select', {
      url: '/select/:lang/:formbuttonId/:promoCode',
      template: ' ',
      controller:['$stateParams','user','stateService','windowService','config',function($stateParams,user,stateService,windowService,config) {
        config.set('formbutton',$stateParams.formbuttonId);
        config.set('promoCode',$stateParams.promoCode);
        stateService.setState({'lang':$stateParams.lang,'formbutton':$stateParams.formbuttonId,'promoCode':$stateParams.promoCode});
        windowService.setWindowState(config);
      }]
    })
    .state('filter', {
      url: '/:lang/filter/:filterParams',
      template: ' ',
      controller:['$stateParams','user','stateService','windowService','config',function($stateParams,user,stateService,windowService,config) {
        var filterParams=$stateParams.filterParams.split('&').reduce(function(filterParams,contactParam) {
          var contactParamKeyAndValue=contactParam.split('=');
          if(contactParamKeyAndValue.length===2) {
            filterParams[contactParamKeyAndValue[0]]=contactParamKeyAndValue[1];
          }
          return filterParams;
        },{});
        stateService.setState({'lang':$stateParams.lang,'params':filterParams});
        windowService.setWindowState(config);
      }]
    })
    .state('setup', {
      url: '/setup',
      templateUrl: 'app/views/setupPage.html',
      controller:['stateService','windowService','config',function(stateService,windowService,config) {
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('languages', {
      url: '/languages',
      templateUrl: 'app/views/languagesPage.html',
      controller:['stateService','windowService','config',function(stateService,windowService,config) {
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('language', {
      url: '/language/:lang',
      template: ' ',
      controller:['$scope','$stateParams','user','stateService','windowService','config',function($scope,$stateParams,user,stateService,windowService,config) {
        user.lang=$stateParams.lang;
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('dates', {
      url: '/dates',
      templateUrl: 'app/views/datesPage.html',
      controller:['stateService','windowService','config',function(stateService,windowService,config) {
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('activities', {
      url: '/activities',
      templateUrl: 'app/views/activitiesPage.html',
      controller:['stateService','windowService','config',function(stateService,windowService,config) {
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('credentials', {
      url: '/credentials',
      templateUrl: 'app/views/credentialsPage.html',
      controller:['stateService','windowService','config',function(stateService,windowService,config) {
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('paymentInstructions', {
      url: '/paymentInstructions',
      templateUrl: 'app/views/paymentInstructionsPage.html',
      controller:['stateService','windowService','config',function(stateService,windowService,config) {
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('kioskFinished', {
      url: '/kioskFinished',
      templateUrl: 'app/views/kioskFinishedPage.html',
      controller:['stateService','windowService','config',function(stateService,windowService,config) {
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('detailsPage', {
      url: '/detailsPage',
      templateUrl: 'app/views/detailsPage.html',
      controller:['stateService','windowService','config',function(stateService,windowService,config) {
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('notAvailableToday', {
      url: '/notAvailableToday',
      templateUrl: 'app/views/notAvailableTodayPage.html',
      controller:['stateService','windowService','config',function(stateService,windowService,config) {
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('siteFinished', {
      url: '/siteFinished',
      templateUrl: 'app/views/siteFinishedPage.html',
      controller:['stateService','windowService','config',function(stateService,windowService,config) {
        stateService.setState({});
        windowService.setWindowState(config);
      }]
    })
    .state('updateForEmp', {
      url: '/updateForEmp/:lang/:visitId/:token',
      templateUrl: 'app/views/datesPage.html',
      controller:['$stateParams','stateService','windowService','config',function($stateParams,stateService,windowService,config) {
        stateService.setState({'lang':$stateParams.lang,'visitId':$stateParams.visitId,'token':$stateParams.token});
        windowService.setWindowState(config);
      }]
    })
    ;
}]);
