angular.module('app').controller('SetupPageController',['$scope','tools','Idle','ctep',
                                            function($scope,tools,Idle,ctep) {
  $scope.ctep=ctep;

  $scope.devMode = function() {
    $scope.resetModel();
    var startdate=new Date();
    $scope.onDateChange('startdate',startdate.getTime());
    tools.redirect('languages');
  };

  $scope.ticket = function() {
    $scope.resetModel();
    var startdate=new Date();
    $scope.onDateChange('startdate',startdate.getTime());
    $scope.model.ctepVisit={'id':'79'};
    tools.redirect('kioskFinished');
  };

  Idle.unwatch();

}]);
