'use strict';

var services={};

/*
 * formatting
 */
services.formatting = function() {};
services.formatting.prototype.numberIn = function(number) {
  if(!angular.isString(number)) {
    return number;
  }
  number=number.replace(/^0+/g,'');
  if(number.length===0) {
    return 0;
  }
  return parseInt(number,10);
};
services.formatting.prototype.amountIn=function(amount) {
  var result=0.0;
  if(amount) {
    result=parseFloat(amount.toString().replace(',','.'));
  }
  return result;
};
services.formatting.prototype.dateIn=function(input) {
  if(input) {
    if(angular.isDate(input)) {
      return input;
    } else if(angular.isString(input)) {
      return new Date(
        parseInt(input.substr(0,4),10),
        parseInt(input.substr(5,2),10)-1,
        parseInt(input.substr(8,2),10)
      );
    }
  }
};
angular.module('app').service('formatting',[services.formatting]);

/*
 * modelstore
 */
services.modelstore = function($log,dictionary) {
  this.$log=$log;
  this.dictionary=dictionary;
  this.models={};
  this.missingRecords={};
};
services.modelstore.prototype.getModel = function(name) {
  if(!(name in this.models)) {
    this.models[name]={'modelName':name};
  }
  return this.models[name];
};
services.modelstore.prototype.removeRecord = function(collection,id,model) {
  if((collection in model) && (id in model[collection])) {
    var foreignkeys=this.dictionary.foreignkeys;
    if(collection in foreignkeys && 'out' in foreignkeys[collection]) {
      var record=model[collection][id];
      var outkeys=foreignkeys[collection].out;
      var field,val,index;
      for(var refCol in outkeys) {
        field=outkeys[refCol];
        if(field in record) {
          val=record[field];
          if(refCol in model && val in model[refCol] && collection in model[refCol][val]) {
            index=model[refCol][val][collection].indexOf(id);
            if(index>-1) {
              model[refCol][val][collection].splice(index,1);
            }
          }
        }
      }
    }
    delete model[collection][id];
  }
};
services.modelstore.prototype.addRecord = function(collection,record,model,suppressWarnings) {
  if(angular.isUndefined(suppressWarnings)) {
    suppressWarnings=false;
  }
  if(record && 'id' in record) {
    record.selected=false;
    if(!(collection in model)) {
      model[collection]={};
    }
    var foreignkeys=this.dictionary.foreignkeys;
    if(record.id in model[collection]) {
      var existingRecord=model[collection][record.id];
      if(collection in foreignkeys && 'in' in foreignkeys[collection]) {
        var inkeys=foreignkeys[collection].in;
        for(var inkeyCollection in inkeys) {
          if(inkeyCollection in existingRecord) {
            record[inkeyCollection]=existingRecord[inkeyCollection];
          }
        }
      }
      this.removeRecord(collection,record.id,model);
    } else if((collection in this.missingRecords) && (record.id in this.missingRecords[collection])) {
      var missingRecord=this.missingRecords[collection][record.id];
      for(var missingRecordAttr in missingRecord) {
        record[missingRecordAttr]=missingRecord[missingRecordAttr];
      }
    }
    var formatFields=this.dictionary.formatFields;
    if(collection in formatFields) {
      var fields=formatFields[collection];
      var formatter;
      for(var field in fields) {
        formatter=fields[field];
        if(field in record) {
          record[field]=formatter(record[field]);
        } else {
          if(!suppressWarnings) {
            this.$log.log('WARNING: formatting on '+collection+'.'+field+' failed '+angular.toJson(record));
          }
        }
      }
    }
    model[collection][record.id]=record;
    if(collection in foreignkeys && 'out' in foreignkeys[collection]) {
      var outkeys=foreignkeys[collection].out;
      var foreignField,foreignId,foreignRecord;
      for(var outkeyCollection in outkeys) {
        foreignField=outkeys[outkeyCollection];
        if(foreignField in record) {
          foreignId=record[foreignField];
          if(!(outkeyCollection in model)) {
            model[outkeyCollection]={};
          }
          if(foreignId in model[outkeyCollection]) {
            foreignRecord=model[outkeyCollection][foreignId];
          } else {
            if(!(outkeyCollection in this.missingRecords)) {
              this.missingRecords[outkeyCollection]=[];
            }
            if(!(foreignId in this.missingRecords[outkeyCollection])) {
              this.missingRecords[outkeyCollection][foreignId]={};
            }
            foreignRecord=this.missingRecords[outkeyCollection][foreignId];
          }
          if(!(collection in foreignRecord)) {
            foreignRecord[collection]=[];
          }
          if(foreignRecord[collection].indexOf(record.id)<=-1) {
            foreignRecord[collection].push(record.id);
          }
        }
      }
    }
  }
};
services.modelstore.prototype.addRecords = function(collection,records,model,suppressWarnings) {
  if(angular.isUndefined(suppressWarnings)) {
    suppressWarnings=false;
  }
  for(var i in records) {
    this.addRecord(collection,records[i],model,suppressWarnings);
  }
};
services.modelstore.prototype.cleanModel = function(collections,model) {
  var i,j,collectionName,collection;
  for(i in collections) {
    collectionName=collections[i];
    collection=model[collectionName];
    for(j in collection) {
      this.removeRecord(collectionName,j,model);
    }
  }
};
angular.module('app').service('modelstore',['$log','dictionary',services.modelstore]);

/*
 * user
 */
services.user = function() {
  this.lang=null;
  this.rights=[];
};
services.user.prototype.hasRight = function(rgh) {
  return this.rights.indexOf(rgh)>-1;
};
angular.module('app').service('user',[services.user]);

/*
 * errorlog
 */
services.errorlog = function(toastr) {
  this.toastr=toastr;
};
services.errorlog.prototype.receive = function(type,message) {
  this.toastr[type](message);
};
services.errorlog.prototype.receiveError = function(error) {
  this.error(error);
};
services.errorlog.prototype.error = function(error) {
  this.receive('error',error);
};
services.errorlog.prototype.warning = function(warning) {
  this.receive('warning',warning);
};
services.errorlog.prototype.success = function(success) {
  this.receive('success',success);
};
services.errorlog.prototype.info = function(info) {
  this.receive('info',info);
};
angular.module('app').service('errorlog',['toastr',services.errorlog]);

/*
 * tools
 */
services.tools = function($location,$timeout,$filter,formatting,user,modelstore,errorlog) {
  this.$location=$location;
  this.$timeout=$timeout;
  this.$filter=$filter;
  this.formatting=formatting;
  this.user=user;
  this.modelstore=modelstore;
  this.errorlog=errorlog;
  this.numberIn=formatting.numberIn;
  this.amountIn=formatting.amountIn;
};
services.tools.prototype.isArray=function(obj) {
  return angular.isArray(obj);
};
services.tools.prototype.makeArray=function(obj) {
  var result=obj;
  if(!this.isArray(obj)) {
    result=[obj];
  }
  return result;
};
services.tools.prototype.toString=function(obj) {
  return obj.toString();
};

services.tools.prototype.amountOut=function(amount) {
  var result='0';
  if(amount) {
    result=(Math.floor(100*parseFloat(amount)+0.5)/100.0).toString().replace('.',',');
  }
  return result;
};

var latiniseMap={
  '\u00E0':'a',
  '\u00E1':'a',
  '\u00E2':'a',
  '\u00E4':'a',
  '\u00E8':'e',
  '\u00E9':'e',
  '\u00EA':'e',
  '\u00EB':'e',
  '\u00EC':'i',
  '\u00ED':'i',
  '\u00EE':'i',
  '\u00EF':'i',
  '\u00F2':'o',
  '\u00F3':'o',
  '\u00F4':'o',
  '\u00F6':'o',
  '\u00F9':'u',
  '\u00FA':'u',
  '\u00FB':'u',
  '\u00FC':'u'
};
services.tools.prototype.latinise = function(string) {
  return string.toLowerCase().replace(/[\u00E0-\u00E2\u00E4\u00E8-\u00EF\u00F2-\u00F4\u00F6\u00F9-\u00FC]/g, function(match) {
    return latiniseMap[match];
  });
};

services.tools.prototype.dateToWeekdayCode=function(input) {
  if(input && input!=='0000-00-00') {
    input=input.substr(0,10);
    var dateJava=this.dateToJava(input);
    return (dateJava.getDay()+6)%7+1;
  }
  return '';
};

services.tools.prototype.days={
  '1':{'en':'Monday','nl':'Maandag','fr':'Lundi'},
  '2':{'en':'Tuesday','nl':'Dinsdag','fr':'Mardi'},
  '3':{'en':'Wednesday','nl':'Woensdag','fr':'Mercredi'},
  '4':{'en':'Thursday','nl':'Donderdag','fr':'Jeudi'},
  '5':{'en':'Friday','nl':'Vrijdag','fr':'Vendredi'},
  '6':{'en':'Saturday','nl':'Zaterdag','fr':'Samedi'},
  '7':{'en':'Sunday','nl':'Zondag','fr':'Dimanche'}
};

services.tools.prototype.dateToWeekdayLang=function(input,lang) {
  if(input && input!=='0000-00-00') {
    return this.days[this.dateToWeekdayCode(input)][lang];
  }
  return '';
};

services.tools.prototype.dateToWeekday=function(input) {
  return this.dateToWeekdayLang(input,this.user.lang);
};

services.tools.prototype.dateToWeekdayShortLang=function(input,lang) {
  var long=this.dateToWeekdayLang(input,lang);
  if(long) {
    return long.substr(0,2);
  }
  return '';
};

services.tools.prototype.dateToWeekdayShort=function(input) {
  return this.dateToWeekdayShortLang(input,this.user.lang);
};

services.tools.prototype.months={
  '0': {'en':'January','nl':'januari','fr':'janvier'},
  '1': {'en':'February','nl':'februari','fr':'février'},
  '2': {'en':'March','nl':'maart','fr':'mars'},
  '3': {'en':'April','nl':'april','fr':'avril'},
  '4': {'en':'May','nl':'mei','fr':'mai'},
  '5': {'en':'June','nl':'juni','fr':'juin'},
  '6': {'en':'July','nl':'juli','fr':'juillet'},
  '7': {'en':'August','nl':'augustus','fr':'août'},
  '8': {'en':'September','nl':'september','fr':'septembre'},
  '9': {'en':'October','nl':'oktober','fr':'octobre'},
  '10':{'en':'November','nl':'november','fr':'novembre'},
  '11':{'en':'December','nl':'december','fr':'décembre'}
};

services.tools.prototype.dateToMonthLongLang=function(input,lang) {
  if(input) {
    input=input.substr(0,10);
    if(input!=='0000-00-00') {
      var dateJava=this.dateToJava(input);
      return this.months[dateJava.getMonth()][lang];
    }
  }
  return '';
};

services.tools.prototype.dateToMonthLong=function(input) {
  return this.dateToMonthLongLang(input,this.user.lang);
};

services.tools.prototype.dateToMonthLang=function(input,lang) {
  if(input) {
    input=input.substr(0,10);
    if(input!=='0000-00-00') {
      return this.dateToMonthLongLang(input,lang).substr(0,3);
    }
  }
  return '';
};

services.tools.prototype.dateToMonth=function(input) {
  return this.dateToMonthLang(input,this.user.lang);
};

services.tools.prototype.hourOut=function(input) {
  if(input) {
    if(input.length>8) {
      input=input.substr(11);
    }
    if(input!=='00:00:00') {
      var result=input.substr(0,2)+'h';
      var minutes=input.substr(3,2);
      if(minutes!=='00') {
        result+=minutes;
      }
      return result;
    }
  }
  return '';
};

services.tools.prototype.dateOutLongLang=function(date,lang) {
  if(date) {
    date=date.substr(0,10);
    if(date!=='0000-00-00') {
      var day=date.substr(8,2);
      if(day.substr(0,1)==='0') {
        day=day.substr(1,1);
      }
      return this.dateToWeekdayLang(date,lang)+
        ' '+day+
        ' '+this.dateToMonthLongLang(date,lang);
    }
  }
  return '';
};

services.tools.prototype.dateOutLong=function(date) {
  return this.dateOutLongLang(date,this.user.lang);
};

services.tools.prototype.dateOutLang=function(date,lang) {
  if(date) {
    date=date.substr(0,10);
    if(date!=='0000-00-00') {
      var day=date.substr(8,2);
      if(day.substr(0,1)==='0') {
        day=day.substr(1,1);
      }
      return this.dateToWeekdayShortLang(date,lang)+
        ' '+day+
        ' '+this.dateToMonthLang(date,lang);
    }
  }
  return '';
};

services.tools.prototype.dateOut=function(date) {
  return this.dateOutLang(date,this.user.lang);
};

services.tools.prototype.birthDateOutLang=function(date,lang) {
  if(date) {
    date=date.substr(0,10);
    if(date!=='0000-00-00') {
      return this.dateOutLang(date, lang)+' '+date.substr(0,4);
    }
  }
  return '';
};

services.tools.prototype.birthDateOut=function(date) {
  return this.birthDateOutLang(date,this.user.lang);
};

services.tools.prototype.expandDigits=function(nnum,to) {
  nnum=nnum||0;
  var num=nnum.toString();
  while(num.length<to) {
    num='0'+num;
  }
  return num;
};

services.tools.prototype.dateToJava=function(input) {
  if(input) {
    var numberIn=this.numberIn;
    return new Date(
      numberIn(input.substr(0,4)),
      numberIn(input.substr(5,2))-1,
      numberIn(input.substr(8,2))
    );
  }
};

services.tools.prototype.padTwoDigits=function(n) {
  var result=n;
  if(n<10) {
    result='0'+n;
  }
  return result;
};

services.tools.prototype.dateFromJava=function(input) {
  var result;
  if(input) {
    result=input.getFullYear()+'-'+
      this.padTwoDigits(input.getMonth()+1)+'-'+
      this.padTwoDigits(input.getDate());
  } else {
    result='';
  }
  return result;
};

services.tools.prototype.timestampToJava=function(input) {
  if(input) {
    var numberIn=this.numberIn;
    return new Date(
      numberIn(input.substr(0,4)),
      numberIn(input.substr(5,2))-1,
      numberIn(input.substr(8,2)),
      numberIn(input.substr(11,2)),
      numberIn(input.substr(14,2)),
      numberIn(input.substr(17,2)),
      0
    );
  }
};

services.tools.prototype.timestampFromJava=function(input) {
  if(input) {
    var pad=this.padTwoDigits;
    return input.getFullYear()+
      '-'+pad(input.getMonth()+1)+
      '-'+pad(input.getDate())+
      ' '+pad(input.getHours())+
      ':'+pad(input.getMinutes())+
      ':'+pad(input.getSeconds());
  }
};

services.tools.prototype.addDays=function(date,days) {
  var result=new Date(date);
  result.setDate(result.getDate()+days);
  return result;
};

services.tools.prototype.today=function() {
  return this.dateFromJava(new Date());
};

services.tools.prototype.hourFromJava=function(input) {
  if(input) {
    var pad=this.padTwoDigits;
    return pad(input.getHours())+':'+
      pad(input.getMinutes())+':'+
      pad(input.getSeconds());
  }
};

services.tools.prototype.now=function() {
  return this.hourFromJava(new Date());
};

services.tools.prototype.hoursAdd=function(a,b) {
  if(a && b) {
    var numberIn=this.numberIn;
    return this.hourFromJava(new Date(1970,0,1,
      numberIn(a.substr(0,2))+numberIn(b.substr(0,2)),
      numberIn(a.substr(3,2))+numberIn(b.substr(3,2)),
      numberIn(a.substr(6,2))+numberIn(b.substr(6,2)),0));
  }
};

services.tools.prototype.hoursSubtract=function(a,b) {
  if(a && b) {
    var numberIn=this.numberIn;
    return this.hourFromJava(new Date(1970,0,1,
      numberIn(a.substr(0,2))-numberIn(b.substr(0,2)),
      numberIn(a.substr(3,2))-numberIn(b.substr(3,2)),
      numberIn(a.substr(6,2))-numberIn(b.substr(6,2)),0));
  }
};
services.tools.prototype.notZero=function(input) {
  var result='';
  if(parseFloat(input)!==0.0) {
    result=input;
  }
  return result;
};

services.tools.prototype.shortSplit=function(short,index) {
  if(short) {
    var splitted=short.split(' ');
    if(index<splitted.length) {
      return splitted[index].substr(0,3);
    }
  }
};

services.tools.prototype.sepaOgmOut=function(ogm) {
  if(ogm) {
    return ogm.substr(0,4)+' '+ogm.substr(4,4)+' '+ogm.substr(8);
  }
};

services.tools.prototype.ogmOut=function(ogm) {
  if(ogm) {
    return '+++'+ogm.substr(0,3)+'/'+ogm.substr(3,4)+'/'+ogm.substr(7)+'+++';
  }
};

services.tools.prototype.substr = function(str,start,length) {
  var result='';
  if(str) {
    result=str.substr(start,length);
  }
  return result;
};

services.tools.prototype.previous = function() {
  history.go(-1);
};

services.tools.prototype.redirect = function(path) {
  this.$location.path(path);
};

services.tools.prototype._whitelist=function(input,list) {
  var output={};
  var len=list.length;
  for(var i=0;i<len;i++) {
    if(list[i] in input) {
      output[list[i]]=input[list[i]];
    }
  }
  return output;
};

services.tools.prototype.whitelistNoId = function(input,list) {
  var result=null;
  if(this.isArray(input)) {
    result=[];
    for(var i in input) {
      result.push(this._whitelist(input[i],list));
    }
  } else {
    result=this._whitelist(input,list);
  }
  return result;
};

services.tools.prototype.whitelist = function(input,list) {
  list=this.clone(list);
  list.push('id');
  return this.whitelistNoId(input,list);
};

services.tools.prototype.getLength = function(input) {
  var result=0;
  if(this.isArray(input)) {
    result=input.length;
  } else if(input) {
    result=Object.keys(input).length;
  }
  return result;
};

services.tools.prototype.match = function(input,template) {
  var output=[];
  if(input && template) {
    var match;
    for(var id in input) {
      match=true;
      for(var key in template) {
        if(!(key in input[id]) || input[id][key]!==template[key]) {
          match=false;
          break;
        }
      }
      if(match) {
        output.push(input[id]);
      }
    }
  }
  return output;
};

services.tools.prototype.matchForObject = function(input,template) {
  var output={};
  if(input && template) {
    var match;
    for(var id in input) {
      match=true;
      for(var key in template) {
        if(!(key in input[id]) || input[id][key]!==template[key]) {
          match=false;
          break;
        }
      }
      if(match) {
        output[id]=input[id];
      }
    }
  }
  return output;
};

services.tools.prototype.exclude = function(input,template) {
  var output=[];
  if(template) {
    var match;
    for(var id in input) {
      match=true;
      for(var key in template) {
        if(!(key in input[id]) || input[id][key]!==template[key]) {
          match=false;
          break;
        }
      }
      if(!match && input[id]) {
        output.push(input[id]);
      }
    }
  }
  return output;
};

services.tools.prototype.toArray = function(obj) {
  var result=obj;
  if (angular.isObject(obj)) {
    result=Object.keys(obj).map(function(key) {
      return obj[key];
    });
  }
  return result;
};

services.tools.prototype.copyArray = function(input) {
  if(!this.isArray(input)) {
    return this.toArray(input);
  }
  var output=[];
  for(var i=0,len=input.length;i<len;i++) {
    output.push(input[i]);
  }
  return output;
};

services.tools.prototype.clone = function(obj) {
  if (obj===null || !angular.isObject(obj)) {
    return obj;
  }
  var copy=null;
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
  } else if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = this.clone(obj[i]);
    }
  } else if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = this.clone(obj[attr]);
      }
    }
  }
  return copy;
};

services.tools.prototype.concat = function(a,b) {
  return this.toArray(a).concat(this.toArray(b));
};

services.tools.prototype.first = function(input) {
  var result=null;
  if(!this.isArray(input)) {
    input=this.toArray(input);
  }
  if(input && input.length>0) {
    result=input[0];
  }
  return result;
};

services.tools.prototype.sum = function(input,field) {
  var sum=0.0;
  if(input) {
    for(var i in input) {
      sum+=this.amountIn(input[i][field]);
    }
  }
  return sum;
};

services.tools.prototype.greater = function(input,field,val) {
  var result=input;
  if(angular.isDefined(input) && angular.isDefined(val)) {
    result=[];
    for(var i in input) {
      if(input[i][field]>val) {
        result.push(input[i]);
      }
    }
  }
  return result;
};

services.tools.prototype.greaterEqual = function(input,field,val) {
  var result=input;
  if(angular.isDefined(input) && angular.isDefined(val)) {
    result=[];
    for(var i in input) {
      if(input[i][field]>=val) {
        result.push(input[i]);
      }
    }
  }
  return result;
};

services.tools.prototype.less = function(input,field,val) {
  var result=input;
  if(angular.isDefined(input) && angular.isDefined(val)) {
    result=[];
    for(var i in input) {
      if(input[i][field]<val) {
        result.push(input[i]);
      }
    }
  }
  return result;
};

services.tools.prototype.lessEqual = function(input,field,val) {
  var result=input;
  if(angular.isDefined(input) && angular.isDefined(val)) {
    result=[];
    for(var i in input) {
      if(input[i][field]<=val) {
        result.push(input[i]);
      }
    }
  }
  return result;
};

services.tools.prototype.max = function(input,field) {
  if(input) {
    return this.toArray(input).reduce(function(acc,cur) {
      return (acc===null || cur[field]>acc[field])?cur:acc;
    },null);
  }
};

services.tools.prototype.maxInt = function(input,field) {
  if(input) {
    return this.toArray(input).reduce(function(acc,cur) {
      return (acc===null || parseInt(cur[field],10)>parseInt(acc[field],10))?cur:acc;
    },null);
  }
};

services.tools.prototype.min = function(input,field) {
  if(input) {
    return this.toArray(input).reduce(function(acc,cur) {
      return (acc===null || cur[field]<acc[field])?cur:acc;
    },null);
  }
};

services.tools.prototype.minInt = function(input,field) {
  if(input) {
    return this.toArray(input).reduce(function(acc,cur) {
      return (acc===null || parseInt(cur[field],10)<parseInt(acc[field],10))?cur:acc;
    },null);
  }
};

services.tools.prototype.addElement = function(input,ele) {
  if(input) {
    var output=this.copyArray(input);
    output.push(ele);
    return output;
  }
};

services.tools.prototype.timestampOutLang = function(timestamp,lang) {
  if(timestamp) {
    var result=this.dateOutLang(timestamp,lang);
    if(timestamp.length>10) {
      result+=' '+this.hourOut(timestamp);
    }
    return result;
  }
  return '';
};

services.tools.prototype.timestampOut = function(timestamp) {
  return this.timestampOutLang(timestamp,this.user.lang);
};

services.tools.prototype.byLabel={'en':'by','nl':'door','fr':'par'};
services.tools.prototype.doneByLang = function(timestamp,name,lang) {
  var result='';
  if(timestamp) {
    var timestampOut=this.timestampOutLang(timestamp,lang);
    if(name) {
      result=timestampOut+' '+this.byLabel[lang]+' '+name;
    } else {
      result=timestampOut;
    }
  }
  return result;
};

services.tools.prototype.doneBy = function(timestamp,name) {
  return this.doneByLang(timestamp,name,this.user.lang);
};
services.tools.prototype.shortDoneByLang = function(date,name,lang) {
  var result='';
  if(date) {
    var dateOut=this.dateOutLang(date,lang);
    if(name) {
      result=dateOut+' '+this.byLabel[lang]+' '+name;
    } else {
      result=dateOut;
    }
  }
  return result;
};

services.tools.prototype.shortDoneBy = function(date,name) {
  return this.shortDoneByLang(date,name,this.user.lang);
};

services.tools.prototype.getMinIdMinusOne = function(collection) {
  var minId=0;
  if(collection) {
    collection=this.toArray(collection);
    var id;
    var first=true;
    for(var i=0;i<collection.length;i++) {
      id=parseInt(collection[i].id,10);
      if(first) {
        minId=id;
        first=false;
      } else if(id<minId) {
        minId=id;
      }
    }
  }
  minId-=1;
  return minId.toString();
};

services.tools.prototype.timeout = function(f,duration) {
  this.$timeout(f,duration);
};

services.tools.prototype.startFrom = function(input, start) {
  if(input) {
    if(!this.isArray(input)) {
      input=this.toArray(input);
    }
    start = Number(start);
    return input.slice(start);
  }
};

services.tools.prototype.orderBy = function(input,field) {
  return this.$filter('orderBy')(input,field);
};

services.tools.prototype._joinRecord=function(result,record,collectionName,returnArray,model) {
  if(angular.isDefined(record) && (collectionName in model) && (collectionName in record)) {
    var collection=model[collectionName];
    var ids=record[collectionName];
    for(var i in ids) {
      if(collection[ids[i]]) {
        if(returnArray) {
          result.push(collection[ids[i]]);
        } else {
          result[i]=collection[ids[i]];
        }
      }
    }
  }
  return result;
};

services.tools.prototype._joinRecords=function(records,collectionName,returnArray,model) {
  var result=[];
  if(this.isArray(records)) {
    for(var i in records) {
      result=this._joinRecord(result,records[i],collectionName,returnArray,model);
    }
  } else {
    result=this._joinRecord(result,records,collectionName,returnArray,model);
  }
  return result;
};

services.tools.prototype.join = function(records,collectionName,model) {
  return this._joinRecords(records,collectionName,true,model);
};

services.tools.prototype.objectJoin = function(records,collectionName,model) {
  return this._joinRecords(records,collectionName,false,model);
};

services.tools.prototype.inverseJoin = function(records,collectionName,foreignkey,model) {
  var outRecords=[];
  if(records && (collectionName in model)) {
    var collection=model[collectionName];
    var id;
    var outIds=[];
    for(var i in records) {
      id=records[i][foreignkey];
      if(id in collection && outIds.indexOf(id)<=-1) {
        outRecords.push(collection[id]);
        outIds.push(id);
      }
    }
  }
  return outRecords;
};

services.tools.prototype.initDates = function(startDiff,endDiff,model) {
  var startdate = new Date();
  if(startDiff!==0) {
    startdate.setDate(startdate.getDate()+startDiff);
  }
  model.startdate=startdate;
  var enddate = new Date();
  if(endDiff!==0) {
    enddate.setDate(enddate.getDate()+endDiff);
  }
  model.enddate=enddate;
};

services.tools.prototype.validateAndUpdateDates = function(model) {
  var result=false;
  if('startdate' in model && 'enddate' in model) {
    if(Math.round((model.enddate.getTime() - model.startdate.getTime()) / (1000 * 3600 * 24))<=31) {
      result=true;
    } else {
      this.errorlog.receiveError('Interval too wide. The maximum is 31 days.');
    }
  }
  return result;
};

services.tools.prototype.filterDates = function(model) {
  model=model||this.modelstore.getModel();
  var output=[];
  if(('dates' in model) && ('startdateValue' in model) && ('enddateValue' in model)) {
    for(var id in model.dates) {
      if(id>=model.startdateValue && id<=model.enddateValue) {
        output.push(model.dates[id]);
      }
    }
  }
  return output;
};

services.tools.prototype.initAttribute = function(key,value,model) {
  if(!(key in model)) {
    model[key]=value;
  }
};
services.tools.prototype.initAttributes = function(model,obj) {
  angular.forEach(obj,function(value,key) {
    if(!(key in model)) {
      model[key]=value;
    }
  });
};
services.tools.prototype.utf8Encode = function(string) {
  string = string.replace(/\r\n/g,'\n');
  var utftext = '';
  for (var n = 0; n < string.length; n++) {
    var c = string.charCodeAt(n);
    if (c < 128) {
      utftext += String.fromCharCode(c);
    } else if((c > 127) && (c < 2048)) {
      utftext += String.fromCharCode((c >> 6) | 192);
      utftext += String.fromCharCode((c & 63) | 128);
    } else {
      utftext += String.fromCharCode((c >> 12) | 224);
      utftext += String.fromCharCode(((c >> 6) & 63) | 128);
      utftext += String.fromCharCode((c & 63) | 128);
    }
  }
  return utftext;
};
services.tools.prototype._keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
services.tools.prototype.encode64=function(input) {
  var output = '';
  var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
  var i = 0;
  input = this.utf8Encode(input);
  while (i < input.length) {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);
    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;
    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output = output +
    this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
    this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
  }
  return output;
};
services.tools.prototype.hash = function(e) {
  var hash = 0;
  var len=e.length;
  if (len === 0) {
    return hash;
  }
  var c;
  for(var i = 0; i < len; i++) {
    c = e.charCodeAt(i);
    hash = ((hash<<5)-hash)+c;
    hash &= hash;
  }
  return hash;
};
// services.tools.prototype.hash256 = function(str) {
//   var chrsz=8;
//   var hexcase=0;

//   function safeAdd (x, y) {
//     var lsw = (x & 0xFFFF) + (y & 0xFFFF);
//     var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
//     return (msw << 16) | (lsw & 0xFFFF);
//   }

//   function s(X,n) {
//     return (X >>> n) | (X << (32 - n));
//   }
//   function r(X,n) {
//     return (X >>> n);
//   }
//   function ch(x,y,z) {
//     return ((x & y) ^ ((~x) & z));
//   }
//   function maj(x,y,z) {
//     return ((x & y) ^ (x & z) ^ (y & z));
//   }
//   function sigma0256(x) {
//     return (s(x,2) ^ s(x,13) ^ s(x,22));
//   }
//   function sigma1256(x) {
//     return (s(x,6) ^ s(x,11) ^ s(x,25));
//   }
//   function gamma0256(x) {
//     return (s(x,7) ^ s(x,18) ^ r(x,3));
//   }
//   function gamma1256(x) {
//     return (s(x,17) ^ s(x,19) ^ r(x,10));
//   }

//   function coreSha256 (m,l) {
//     var K=[0x428A2F98,0x71374491,0xB5C0FBCF,0xE9B5DBA5,0x3956C25B,0x59F111F1,0x923F82A4,0xAB1C5ED5,0xD807AA98,0x12835B01,0x243185BE,0x550C7DC3,0x72BE5D74,0x80DEB1FE,0x9BDC06A7,0xC19BF174,0xE49B69C1,0xEFBE4786,0xFC19DC6,0x240CA1CC,0x2DE92C6F,0x4A7484AA, 0x5CB0A9DC, 0x76F988DA, 0x983E5152, 0xA831C66D, 0xB00327C8, 0xBF597FC7, 0xC6E00BF3, 0xD5A79147, 0x6CA6351, 0x14292967, 0x27B70A85, 0x2E1B2138, 0x4D2C6DFC, 0x53380D13, 0x650A7354, 0x766A0ABB, 0x81C2C92E, 0x92722C85, 0xA2BFE8A1, 0xA81A664B, 0xC24B8B70, 0xC76C51A3, 0xD192E819, 0xD6990624, 0xF40E3585, 0x106AA070, 0x19A4C116, 0x1E376C08, 0x2748774C, 0x34B0BCB5, 0x391C0CB3, 0x4ED8AA4A, 0x5B9CCA4F, 0x682E6FF3, 0x748F82EE, 0x78A5636F, 0x84C87814, 0x8CC70208, 0x90BEFFFA, 0xA4506CEB, 0xBEF9A3F7, 0xC67178F2];
//     var HASH=[0x6A09E667,0xBB67AE85,0x3C6EF372,0xA54FF53A,0x510E527F,0x9B05688C,0x1F83D9AB,0x5BE0CD19];
//     var W=[64];
//     var a,b,c,d,e,f,g,h,i,j;
//     var T1,T2;

//     m[l >> 5] |= 0x80 << (24 - l % 32);
//     m[((l + 64 >> 9) << 4) + 15] = l;

//     for(i=0;i<m.length;i+=16) {
//       a = HASH[0];
//       b = HASH[1];
//       c = HASH[2];
//       d = HASH[3];
//       e = HASH[4];
//       f = HASH[5];
//       g = HASH[6];
//       h = HASH[7];

//       for(j=0;j<64;j++) {
//         if(j < 16) {
//           W[j] = m[j + i];
//         } else {
//           W[j] = safeAdd(safeAdd(safeAdd(gamma1256(W[j - 2]), W[j - 7]), gamma0256(W[j - 15])), W[j - 16]);
//         }

//         T1 = safeAdd(safeAdd(safeAdd(safeAdd(h, sigma1256(e)), ch(e, f, g)), K[j]), W[j]);
//         T2 = safeAdd(sigma0256(a), maj(a, b, c));

//         h = g;
//         g = f;
//         f = e;
//         e = safeAdd(d, T1);
//         d = c;
//         c = b;
//         b = a;
//         a = safeAdd(T1, T2);
//       }

//       HASH[0] = safeAdd(a, HASH[0]);
//       HASH[1] = safeAdd(b, HASH[1]);
//       HASH[2] = safeAdd(c, HASH[2]);
//       HASH[3] = safeAdd(d, HASH[3]);
//       HASH[4] = safeAdd(e, HASH[4]);
//       HASH[5] = safeAdd(f, HASH[5]);
//       HASH[6] = safeAdd(g, HASH[6]);
//       HASH[7] = safeAdd(h, HASH[7]);
//     }
//     return HASH;
//   }

//   function str2binb (str) {
//     var bin=[];
//     var mask=(1 << chrsz) - 1;
//     for(var i = 0; i < str.length * chrsz; i += chrsz) {
//       bin[i>>5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i % 32);
//     }
//     return bin;
//   }

//   function utf8Encode(string) {
//     string = string.replace(/\r\n/g,'\n');
//     var utftext = '';
//     for (var n = 0; n < string.length; n++) {
//       var c = string.charCodeAt(n);
//       if (c < 128) {
//         utftext += String.fromCharCode(c);
//       } else if((c > 127) && (c < 2048)) {
//         utftext += String.fromCharCode((c >> 6) | 192);
//         utftext += String.fromCharCode((c & 63) | 128);
//       } else {
//         utftext += String.fromCharCode((c >> 12) | 224);
//         utftext += String.fromCharCode(((c >> 6) & 63) | 128);
//         utftext += String.fromCharCode((c & 63) | 128);
//       }
//     }
//     return utftext;
//   }

//   function binb2hex (binarray) {
//     var hexTab=hexcase?'0123456789ABCDEF':'0123456789abcdef';
//     var str='';
//     for(var i=0;i<binarray.length*4;i++) {
//       str+=hexTab.charAt((binarray[i>>2]>>((3-i%4)*8+4))&0xF)+hexTab.charAt((binarray[i>>2]>>((3-i%4)*8))&0xF);
//     }
//     return str;
//   }
//   str = utf8Encode(str);
//   return binb2hex(coreSha256(str2binb(str), str.length * chrsz));
// };
services.tools.prototype.label = function(label) {
  var result='';
  if(label && ('label'+this.user.lang in label)) {
    result=label['label'+this.user.lang];
  }
  return result;
};
services.tools.prototype.short = function(label) {
  var result='';
  if(label && ('short'+this.user.lang in label)) {
    result=label['short'+this.user.lang];
  }
  return result;
};
services.tools.prototype.lang = function(label) {
  var result='';
  if(label && (this.user.lang in label)) {
    result=label[this.user.lang];
  }
  return result;
};
services.tools.prototype.emailClass = function(value) {
  var result='required';
  if(value) {
    var re = /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(value)) {
      result='valid';
    } else {
      result='error';
    }
  }
  return result;
};
services.tools.prototype.dateClass = function(value) {
  var result='required';
  if(value) {
    var re = /^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/;
    if(re.test(value)) {
      result='valid';
    } else {
      result='error';
    }
  }
  return result;
};
services.tools.prototype.requiredClass = function(value) {
  var result='required';
  if(value) {
    result='valid';
  }
  return result;
};
services.tools.prototype.passwordClass = function(value) {
  var result='required';
  if(value) {
    if(value.length>=8) {
      result='valid';
    } else {
      result='error';
    }
  }
  return result;
};
services.tools.prototype.confirmPasswordClass = function(password,confirmPassword) {
  var result='required';
  if(confirmPassword) {
    if(password===confirmPassword) {
      result='valid';
    } else {
      result='error';
    }
  }
  return result;
};
services.tools.prototype.xor = function(a,b) {
  return (!a && b) || (a && !b);
};
services.tools.prototype.sortId = function(a) {
  return parseInt(a.id,10);
};
services.tools.prototype.coalesce = function(value,def) {
  var result;
  if(angular.isUndefined(value) || value===null || value==='') {
    result=def;
  } else {
    result=value;
  }
  return result;
};
services.tools.prototype.round = function(value,decimals) {
  var pow=Math.pow(10.0,decimals);
  return Math.round(this.amountIn(value)*pow)/pow;
};
services.tools.prototype.push = function(parent,name,record) {
  if(!(name in parent)) {
    parent[name]=[];
  }
  parent[name].push(record);
};
services.tools.prototype.replaceAll = function(mapObj,str) {
  for(var i in mapObj) {
    str=str.replace(i,mapObj[i]);
  }
  return str;
};
angular.module('app').service('tools',['$location','$timeout','$filter','formatting','user','modelstore','errorlog',services.tools]);

/*
 * parameters
 */
services.parameters = function(user) {
  this.user=user;
};
services.parameters.prototype.combinationFilter = function(id2s,comb,id1) {
  var result={};
  if(id1 && (comb in this) && (id1 in this[comb])) {
    var combinations=this[comb][id1].id2s;
    var combination;
    for(var i = 0, len = combinations.length; i < len; i++) {
      combination=combinations[i];
      if(combination in id2s) {
        result[combination]=id2s[combination];
      }
    }
  }
  return result;
};
services.parameters.prototype.inverseCombinationFilter = function(id1s,comb,id2) {
  var result={};
  if(id1s && (comb in this) && id2) {
    for(var i in id1s) {
      if(i in this[comb] && id2 in this[comb][i].id2s) {
        result[i]=id1s[i];
      }
    }
  }
  return result;
};
services.parameters.prototype.checkCombination = function(comb,id1,id2) {
  return (comb in this) && (id1 in this[comb]) && (this[comb][id1].id2s.indexOf(id2.toString())>-1);
};
services.parameters.prototype.combinationCompare = function(comb,id1s,id2s) {
  var len2=id2s.length;
  if(len2===0) {
    return true;
  }
  if(!(comb in this)) {
    return false;
  }
  var i,j,id1,sub;
  var len1=id1s.length;
  for(i=0;i<len1;i++) {
    id1=id1s[i];
    if(!(id1 in this[comb])) {
      return false;
    }
    sub=this[comb][id1].id2s;
    for(j=0;j<len2;j++) {
      if(sub.indexOf(id2s[j])<=-1) {
        return false;
      }
    }
  }
  return true;
};
angular.module('app').service('parameters',['user',services.parameters]);

services.windowService = function($q,$window,localStorageService) {
  this.$q=$q;
  this.$window=$window;
  this.localStorageService=localStorageService;
  this.windowState='reloading';
  this.windowLoaded=false;
  this.windowStateDefered=null;
};
services.windowService.prototype.setWindowState = function(config) {
  var defered=this.$q.defer();
  var windowService=this;
  if(windowService.windowLoaded===false) {
    var ffo=config.get('ffo');
    var gtmId=config.exists('gtmId')?config.get('gtmId'):false;
    windowService.windowLoaded=true;
    var loadTime=new Date();
    var unloadTime=new Date(angular.fromJson(windowService.localStorageService.get('unloadTime_'+ffo)));
    var refreshTime=loadTime.getTime()-unloadTime.getTime();
    if(refreshTime>3000) {
      windowService.windowState='new';
    }
    if(gtmId) {
      this.activateGtm(window,document,'script','dataLayer',gtmId);
    }
    if(windowService.windowStateDefered!==null) {
      windowService.windowStateDefered.resolve(windowService.windowState);
    }
    defered.resolve(windowService.windowState);
  } else {
    defered.reject();
  }
  return defered.promise;
};
services.windowService.prototype.getWindowState = function() {
  var result=null;
  if(this.windowLoaded) {
    result=this.$q.resolve(this.windowState);
  } else {
    if(this.windowStateDefered===null) {
      this.windowStateDefered=this.$q.defer();
    }
    result=this.windowStateDefered.promise;
  }
  return result;
};
services.windowService.prototype.activateGtm = function(w,d,s,l,i) {
  w[l]=w[l]||[];
  w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
  var f=d.getElementsByTagName(s)[0];
  var j=d.createElement(s);
  var dl=l==='dataLayer'?'':'&l='+l;
  j.async=true;
  j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
  f.parentNode.insertBefore(j,f);
};
angular.module('app').service('windowService',['$q','$window','localStorageService',services.windowService]);

services.stateService = function($q) {
  this.$q=$q;
  this.state={};
  this.stateIsset=false;
  this.stateDefered=null;
};
services.stateService.prototype.setState = function(state) {
  var defered=this.$q.defer();
  var stateService=this;
  if(stateService.stateIsset===false) {
    stateService.stateIsset=true;
    stateService.state=state;
    if(stateService.stateDefered!==null) {
      stateService.stateDefered.resolve(stateService.state);
    }
    defered.resolve(stateService.state);
  } else {
    defered.reject();
  }
  return defered.promise;
};
services.stateService.prototype.getState = function() {
  var result=null;
  if(this.stateIsset) {
    result=this.$q.resolve(this.state);
  } else {
    if(this.stateDefered===null) {
      this.stateDefered=this.$q.defer();
    }
    result=this.stateDefered.promise;
  }
  return result;
};
angular.module('app').service('stateService',['$q',services.stateService]);

/*
 * webservice
 */
services.webservice = function($log,$q,$window,$http,$uibModal,config,localStorageService,user,tools,parameters,modelstore,errorlog,navTreeService) {
  this.$log=$log;
  this.$q=$q;
  this.$window=$window;
  this.$http=$http;
  this.$uibModal=$uibModal;
  this.config=config;
  this.localStorageService=localStorageService;
  this.user=user;
  this.tools=tools;
  this.parameters=parameters;
  this.modelstore=modelstore;
  this.errorlog=errorlog;
  this.navTreeService=navTreeService;
  this.email='';
  this.password='';
  this.loggedIn=false;
  this.auth='';
  this.loginDeferred=null;
  this.technicalErrorMessage='Technical error. Please contact your supplier.';
  this.calling=false;
};
services.webservice.prototype.setAuth = function(email,password) {
  if(email===null && password===null) {
    this.email=null;
    this.password=null;
    this.auth=null;
  } else {
    if(email!==null) {
      this.email=email;
    }
    if(password!==null) {
      this.password=password;
    }
    this.auth=this.tools.encode64(this.email+':'+this.password);
  }
};

services.webservice.prototype.handleResponse = function(type,data,model) {
  var result=null;
  var webservice=this;
  if(webservice.tools.isArray(data)) {
    var response=null;
    var len=data.length;
    if(len===0) {
      webservice.$log.log('ERROR: empty response');
    }
    var collection,j;
    for(var i=0; i<len; i++) {
      webservice.$log.log(type+' received: '+angular.toJson(data[i]));
      if('res' in data[i]) {
        response=data[i].res;
      } else if('err' in data[i]) {
        webservice.errorlog.receiveError(data[i].err);
      } else if('war' in data[i]) {
        webservice.errorlog.receiveWarning(data[i].war);
      } else if('del' in data[i]) {
        collection=data[i].del;
        var ids=data[i].val;
        for(j in ids) {
          webservice.modelstore.removeRecord(collection,ids[j],model);
        }
      } else if('par' in data[i]) {
        model[data[i].par]=data[i].val;
      } else if('col' in data[i]) {
        collection=data[i].col;
        var records=data[i].val;
        if(!webservice.tools.isArray(records)) {
          webservice.errorlog.receiveError(webservice.technicalErrorMessage);
          webservice.$log.log('ERROR: received collection that is not an array: '+collection);
          continue;
        }
        if(records.length===0) {
          continue;
        }
        if(collection==='deleted') {
          webservice.errorlog.receiveError(webservice.technicalErrorMessage);
          webservice.$log.log('ERROR: received the deleted collection');
        }
        for(j in records) {
          webservice.modelstore.addRecord(collection,records[j],model);
        }
      }
    }
    result=webservice.$q.resolve(response);
  } else {
    webservice.errorlog.receiveError(webservice.technicalErrorMessage);
    webservice.$log.log('ERROR: data is not an array '+angular.toJson(data));
    result=webservice.$q.reject(false);
  }
  return result;
};
services.webservice.prototype.http = function(method,url,data,model,file) {
  var webservice=this;
  var httpOrUpload;
  var protocol;
  if(angular.isDefined(file)) {
    httpOrUpload=webservice.Upload.upload({'url':url,'method':method,'file':file,'data':data});
    protocol='Upload';
  } else {
    httpOrUpload=webservice.$http({'url':url,'method':method,'data':angular.toJson(data)});
    protocol='HTTP';
  }
  return httpOrUpload.then(function(data,status) {
    var result=null;
    if(angular.isObject(data) && 'status' in data) {
      status=data.status;
      data=data.data;
    }
    status=parseInt(status,10);
    if(status===200) {
      result=webservice.handleResponse(protocol,data,model);
    } else {
      if(status===401) {
        if(webservice.auth) {
          webservice.logout();
        }
      } else {
        webservice.errorlog.receiveError('Technical error '+data.status+'. Please contact your supplier.');
      }
      result=webservice.$q.reject(false);
    }
    return result;
  },function() {
    return webservice.$q.reject(false);
  });
};

services.webservice.prototype.call = function(task,params,script,model,file) {
  var protocol;
  if(angular.isDefined(file)) {
    protocol='Upload';
  } else {
    protocol='HTTP';
  }
  this.$log.log(protocol+' calling: '+task+' '+script+' '+angular.toJson(params));
  var data={
    's':script,
    't':task,
    'p':params
  };
  if(this.auth) {
    data.a=this.auth;
  }
  var webservice=this;
  return this.http('POST',this.config.get('serviceUrl'),data,model,file).then(function (response) {
    if(response) {
      webservice.$log.log(protocol+' resolving: '+task+' '+script+' '+angular.toJson(params));
    }
    return response;
  });
};

services.webservice.prototype.exclusiveCall = function(task,params,script,model,file) {
  var result;
  var webservice=this;
  if(webservice.calling) {
    result=webservice.$q.reject();
  } else {
    webservice.calling=true;
    result=webservice.call(task,params,script,model,file).then(function(response) {
      var result;
      webservice.calling=false;
      if(response===false) {
        result=webservice.$q.reject();
      } else {
        result=response;
      }
      return result;
    },function(reason) {
      webservice.calling=false;
      return webservice.$q.reject(reason);
    });
  }
  return result;
};

services.webservice.prototype.login = function(email,password,remember) {
  var defered=this.$q.defer();
  var rememberTerm=new Date();
  rememberTerm.setDate(rememberTerm.getDate() + 1);
  rememberTerm.setHours(4,0,0);
  var expiry=this.localStorageService.get('expiry');
  if(expiry) {
    expiry=this.tools.timestampToJava(expiry);
    var now=new Date();
    if(expiry>now && expiry<=rememberTerm) {
      this.setAuth(this.localStorageService.get('email'), this.localStorageService.get('password'));
    } else {
      this.localStorageService.clear();
    }
  }
  var setLocalStorage=false;
  if(!this.auth) {
    if(email && password) {
      this.setAuth(email, password);
      setLocalStorage=remember;
    }
  }
  if(this.auth) {
    var model={};
    var webservice=this;
    this.call('initParams',{},'shared',model).then(function(response) {
      if(response) {
        if(setLocalStorage) {
          webservice.localStorageService.set('expiry',webservice.tools.timestampFromJava(rememberTerm));
          webservice.localStorageService.set('email',webservice.email);
          webservice.localStorageService.set('password',webservice.password);
        }
        webservice.loggedIn=true;
        var value;
        for(var key in model) {
          value=model[key];
          if(key==='navTree') {
            webservice.navTreeService.navTree=value;
          } else if(key==='user') {
            webservice.user.id=value.id;
            webservice.user.name=value.name;
            webservice.user.lang=value.lang;
            webservice.user.role=value.role;
            webservice.user.rights=value.rights;
          } else if(key===key.toUpperCase()) {
            webservice.parameters[key]=value;
            webservice.parameters['notDel'+key]=webservice.tools.matchForObject(value,{'del':'0'});
            webservice.parameters['array'+key]=webservice.tools.toArray(value);
            webservice.parameters['notDelArray'+key]=webservice.tools.toArray(webservice.parameters['notDel'+key]);
          }
        }
        webservice.callCron();
        if(webservice.loginDeferred!==null) {
          webservice.loginDeferred.resolve();
        }
        defered.resolve();
      } else {
        webservice.localStorageService.clear();
        webservice.setAuth(null, null);
        defered.reject();
      }
    });
  } else {
    defered.reject();
  }
  return defered.promise;
};

services.webservice.prototype.afterLogin = function() {
  var result=null;
  if(this.loggedIn) {
    result=this.$q.resolve();
  } else {
    if(this.loginDeferred===null) {
      this.loginDeferred=this.$q.defer();
    }
    result=this.loginDeferred.promise;
  }
  return result;
};

services.webservice.prototype.callCron = function() {
  var webservice=this;
  this.call('cron',{},'shared',{}).then(function(response) {
    if(response==='busy') {
      webservice.callCron();
    }
  });
};

services.webservice.prototype.logout=function() {
  this.localStorageService.clear();
  this.tools.redirect('');
  this.$window.location.reload();
};

services.webservice.prototype.verifyPassword = function(password) {
  return this.password===password;
};

services.webservice.prototype.deleteConfirmLabel={'en':'Delete','nl':'Verwijder','fr':'Supprimer'};
services.webservice.prototype.deleteConfirmModal = function(name) {
  return this.$uibModal.open({
    'template': '<div class="modal-body" id="modal-body">{{wording}}</div><div class="modal-footer"><button class="btn btn-primary" type="button" ng-click="ok()"">OK</button><button class="btn btn-warning" type="button" ng-click="cancel()"">Cancel</button></div>',
    'controller': 'DeleteConfirmModalController',
    'resolve': {
      'wording': function() {
        return name;
      }
    }
  }).result;
};
services.webservice.prototype.deleteConfirm = function(name,method,id,script,model) {
  var webservice=this;
  return this.deleteConfirmModal(webservice.deleteConfirmLabel[webservice.user.lang]+' '+name+'?').then(function() {
    return webservice.call(method,{'id':id},script,model);
  });
};
angular.module('app').service('webservice',['$log','$q','$window','$http','$uibModal','config','localStorageService','user','tools','parameters','modelstore','errorlog','navTreeService',services.webservice]);

/*
 * emailbox
 */
services.emailbox = function($uibModal,tools) {
  this.$uibModal=$uibModal;
  this.tools=tools;
  /*
  this.show=false;
  this.recipient='';
  this.subject='';
  this.attachment='';
  this.body='';
  this.send=null;
  */
};
services.emailbox.prototype.show = function(fromMailOptions,recipient,subject,attachment,body,readonly) {
  if(angular.isUndefined(readonly)) {
    readonly=false;
  }
  return this.$uibModal.open({
    'template':
      '<div class="modal-body">'+
      '  <form class="form-horizontal">'+
      '    <div class="form-group">'+
      '      <label for="fromMail" class="col-sm-2 control-label">From</label>'+
      '      <div class="col-sm-10">'+
      '        <select class="form-control" ng-model="fromMail" ng-options="fromMailElement for fromMailElement in fromMailOptions"'+
      '                '+(readonly?' readonly':'')+'></select>'+
      '      </div>'+
      '    </div>'+
      '    <div class="form-group">'+
      '      <label for="recipient" class="col-sm-2 control-label">To</label>'+
      '      <div class="col-sm-10">'+
      '        <input type="text" id="recipient" class="form-control" ng-model="recipient" readonly />'+
      '      </div>'+
      '    </div>'+
      '    <div class="form-group">'+
      '      <label for="subject" class="col-sm-2 control-label">Subject</label>'+
      '      <div class="col-sm-10">'+
      '        <input type="text" id="subject" class="form-control" ng-model="subject"'+(readonly?' readonly':'')+' />'+
      '      </div>'+
      '    </div>'+
      '    <div class="form-group" ng-show="attachment!=\'\'">'+
      '      <label for="attachment" class="col-sm-2 control-label">Attachment</label>'+
      '      <div class="col-sm-10">'+
      '        <input type="text" id="attachment" class="form-control" ng-model="attachment" readonly />'+
      '      </div>'+
      '    </div>'+
      '    <textarea class="form-control" rows="11" ng-model="body"'+(readonly?' readonly':'')+' ></textarea>'+
      '  </form>'+
      '</div>'+
      '<div class="modal-footer">'+
      (readonly?'':'  <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>')+
      '  <button class="btn btn-warning" type="button" ng-click="cancel()">Cancel</button>'+
      '</div>',
    'controller': 'EmailboxModalController',
    'resolve': {
      'fromMailOptions': function() {
        return fromMailOptions;
      },
      'recipient': function() {
        return recipient;
      },
      'subject': function() {
        return subject;
      },
      'attachment': function() {
        return attachment;
      },
      'body': function() {
        return body;
      }
    }
  }).result;
};
services.emailbox.prototype.showTemplate = function(fromMailOptions,recipient,subject,attachment,body,readonly) {
  if(angular.isUndefined(readonly)) {
    readonly=false;
  }
  return this.$uibModal.open({
    'template':
      '<div class="modal-body">'+
      '  <form class="form-horizontal">'+
      '    <div class="form-group">'+
      '      <label for="fromMail" class="col-sm-2 control-label">From</label>'+
      '      <div class="col-sm-10">'+
      '        <select class="form-control" ng-model="fromMail" ng-options="fromMailElement for fromMailElement in fromMailOptions"'+
      '                '+(readonly?' readonly':'')+'></select>'+
      '      </div>'+
      '    </div>'+
      '    <div class="form-group">'+
      '      <label for="recipient" class="col-sm-2 control-label">To</label>'+
      '      <div class="col-sm-10">'+
      '        <input type="text" id="recipient" class="form-control" ng-model="recipient" readonly />'+
      '      </div>'+
      '    </div>'+
      '    <div class="form-group">'+
      '      <label for="subject" class="col-sm-2 control-label">Subject</label>'+
      '      <div class="col-sm-10">'+
      '        <input type="text" id="subject" class="form-control" ng-model="subject"'+(readonly?' readonly':'')+' />'+
      '      </div>'+
      '    </div>'+
      '    <div class="form-group" ng-show="attachment!=\'\'">'+
      '      <label for="attachment" class="col-sm-2 control-label">Attachment</label>'+
      '      <div class="col-sm-10">'+
      '        <input type="text" id="attachment" class="form-control" ng-model="attachment" readonly />'+
      '      </div>'+
      '    </div>'+
      body+
      '  </form>'+
      '</div>'+
      '<div class="modal-footer">'+
      (readonly?'':'  <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>')+
      '  <button class="btn btn-warning" type="button" ng-click="cancel()">Cancel</button>'+
      '</div>',
    'controller': 'EmailboxModalController',
    'windowClass':'app-modal-window',
    'resolve': {
      'fromMailOptions': function() {
        return fromMailOptions;
      },
      'recipient': function() {
        return recipient;
      },
      'subject': function() {
        return subject;
      },
      'attachment': function() {
        return attachment;
      },
      'body': function() {
        return body;
      }
    }
  }).result;
};
services.emailbox.prototype.openQuotationnoteModal = function(visit,quotationnote) {
  var ths=this;
  return ths.$uibModal.open({
    'template':
      '<div class="modal-body">'+
      '  <form class="form-horizontal">'+
      '    <div class="form-group">'+
      '      <label for="day" class="col-sm-2 control-label">'+ths.tools.lang({'en':'Day','nl':'Dag','fr':'Jour'})+'</label>'+
      '      <div class="col-sm-10">'+
      '        <input type="text" id="day" class="form-control" ng-model="quotationnote.day" />'+
      '      </div>'+
      '    </div>'+
      '    <textarea class="form-control" rows="11" ng-model="quotationnote.body" ></textarea>'+
      '  </form>'+
      '</div>'+
      '<div class="modal-footer">'+
      '  <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>'+
      '  <button class="btn btn-warning" type="button" ng-click="cancel()">Cancel</button>'+
      '</div>',
    'controller': 'QuotationnoteModalController',
    'resolve': {
      'visit': function() {
        return visit;
      },
      'quotationnote': function() {
        return quotationnote;
      }
    }
  }).result;
};
angular.module('app').service('emailbox',['$uibModal','tools',services.emailbox]);
