angular.module('app').service('dictionary', ['formatting',function(formatting) {
  this.formatting=formatting;
  var numberIn=formatting.numberIn;
  var amountIn=formatting.amountIn;
  var dateIn=formatting.dateIn;
  this.formatFields={
    'visits':{},
    'activities':{},
    'items':{'quantity':amountIn,'grossunitprice':amountIn,'reductionamount':amountIn},
    'promotions':{'amount':amountIn},
    'promotionrestrictions':{},
    'meals':{},
    'templateimages':{'zoomwidth':numberIn,'height':numberIn},
    'templatespacings':{'height':numberIn},
    'formbuttons':{'startdate':dateIn,'enddate':dateIn,'fixedstartdate':dateIn,'fixedenddate':dateIn,'minpackquantity':amountIn},
    'formbuttoncomponents':{'quantity':amountIn,'adultcount':numberIn,'childcount':numberIn},
    'hotelreservationcategories':{}
  };
  this.foreignkeys={
    'contacts':{
      'in':{'contactexternalids':'contact','contactcreditcards':'contact','visits':'owningcontact'}
    },
    'contactexternalids':{
      'out':{'contacts':'contact'}
    },
    'contactcreditcards':{
      'out':{'contacts':'contact'}
    },
    'visits':{
      'out':{'users':'creator','contacts':'owningcontact'},
      'in':{'reductions':'visit','activities':'visit','movements':'visit','tickets':'visit','invoices':'visit','invoicerows':'visit','items':'visit','meals':'visit','products':'visit','emails':'visit','quotationnotes':'visit','hotelreservationcategories':'visit'}
    },
    'reductions':{
      'out':{'visits':'visit'}
    },
    'activities':{
      'out':{'visits':'visit'},
      'in':{'participants':'activity','activityvats':'activity'}
    },
    'participants':{
      'out':{'activities':'activity','items':'item'}
    },
    'items':{
      'out':{'visits':'visit'},
      'in':{'participants':'item','dishes':'item','products':'item','hotelreservationcategories':'item','hotelreservationproducts':'item'}
    },
    'movements':{
      'out':{'movements':'counter','accounts':'account','visits':'visit'},
      'in':{'movements':'counter'}
    },
    'accounts': {
      'out':{'users':'user'},
      'in':{'movements':'account','balances':'account'}
    },
    'tickets': {
      'out':{'visits':'visit'}
    },
    'balances': {
      'out':{'accounts':'account'}
    },
    'users': {
      'in':{'accounts':'user','visits':'creator','assignments':'user','rights':'user'}
    },
    'invoices': {
      'out': {'visits':'visit'}
    },
    'invoicerows': {
      'out': {'visits':'visit'}
    },
    'tabs': {
      'in':{'tabstdlines':'tab','tabfreelines':'tab'}
    },
    'tabstdlines':{
      'out':{'tabs':'tab'}
    },
    'tabfreelines':{
      'out':{'tabs':'tab'}
    },
    'rights':{
      'out':{'users':'user'}
    },
    'promotions':{
      'in':{'promotionrestrictions':'promotion'}
    },
    'promotionrestrictions':{
      'out':{'promotions':'promotion'}
    },
    'packets':{
      'in':{'packetcomponents':'packet'}
    },
    'packetcomponents':{
      'out':{'packets':'packet'}
    },
    'weekschedules':{
      'in':{'weekscheduleentries':'weekschedule'}
    },
    'weekscheduleentries':{
      'out':{'weekschedules':'weekschedule'}
    },
    'meals':{
      'out':{'visits':'visit'},
      'in':{'dishes':'meal'}
    },
    'dishes':{
      'out':{'meals':'meal','items':'item'}
    },
    'products':{
      'out':{'visits':'visit','items':'item'}
    },
    'emails':{
      'out':{'visits':'visit'}
    },
    'rulegroups':{
      'in':{'rulemembers':'rulegroup','ruleintervals':'rulegroup'}
    },
    'rulemembers':{
      'out':{'rulegroups':'rulegroup'}
    },
    'ruleintervals':{
      'out':{'rulegroups':'rulegroup'}
    },
    'templates':{
      'in':{'templatesections':'parenttemplate'}
    },
    'templatesections':{
      'in':{'templatetexts':'templatesection','templateimages':'templatesection','templatebuttons':'templatesection'},
      'out':{'templates':'parenttemplate'}
    },
    'templatetexts':{
      'out':{'templatesections':'templatesection'}
    },
    'templateimages':{
      'out':{'templatesections':'templatesection'}
    },
    'templatebuttons':{
      'out':{'templatesections':'templatesection'}
    },
    'quotationnotes':{
      'out':{'visits':'visit'}
    },
    'formbuttons':{
      'in':{'formbuttoncomponents':'formbutton','formbuttonrelations':'child'}
    },
    'formbuttonrelations':{
      'out':{'formbuttons':'child'}
    },
    'formbuttoncomponents':{
      'out':{'formbuttons':'formbutton'}
    },
    'hotelreservationcategories':{
      'out':{'visit':'visit','items':'item'},
      'in':{'hotelspaceassignments':'reservationcategory','hotelreservationproducts':'reservationcategory'}
    },
    'hotelspaceassignments':{
      'out':{'hotelreservationcategories':'reservationcategory','hotelspaces':'space'},
      'in':{'hotelreservationproducts':'spaceassignment'}
    },
    'hotelspaces':{
      'in':{'hotelspaceassignments':'space'}
    },
    'hotelreservationproducts': {
      'out':{'hotelreservationcategories':'reservationcategory','items':'item'}
    }
  };
}]);
