angular.module('app').controller('DatesPageController',['$scope','$window','$timeout','$q','tools','webservice','user','errorlog','config','parameters',
                                            function($scope,$window,$timeout,$q,tools,webservice,user,errorlog,config,parameters) {
  var model=$scope.model;
  var script='public/reservationC';

  $window.scrollTo(0, 0);

  $scope.proceed = function() {
    if(angular.isUndefined(model.startdate)) {
      if($scope.isCatering()) {
        errorlog.error(tools.lang({
          'en':'Please select a date',
          'nl':'Selecteer een datum',
          'fr':'Veuillez s\u00E9lectionner une date'
        }));
      } else {
        errorlog.error(tools.lang({
          'en':'Please select an arrival date',
          'nl':'Selecteer een aankomstdatum',
          'fr':'Veuillez s\u00E9lectionner une date d\u0027arriv\u00E9e'
        }));
      }
    } else if (model.multipleDays!=='0' && angular.isUndefined(model.enddate)) {
      errorlog.error(tools.lang({
        'en':'Please select an end date',
        'nl':'Gelieve een einddatum te selecteren',
        'fr':'Veuillez s\u00E9lectionner une date de fin'
      }));
    } else {
      var startdate=tools.dateFromJava(model.startdate);
      var enddate=tools.dateFromJava(model.multipleDays==='0'?model.startdate:model.enddate);
      if(startdate.length!==10) {
        if($scope.isCatering()) {
          errorlog.error(tools.lang({
            'en':'Please select a date',
            'nl':'Selecteer een datum',
            'fr':'Veuillez s\u00E9lectionner une date'
          }));
        } else {
          errorlog.error(tools.lang({
            'en':'Please select an arrival date',
            'nl':'Selecteer een aankomstdatum',
            'fr':'Veuillez s\u00E9lectionner une date d\u0027arriv\u00E9e'
          }));
        }
      } else if(enddate.length!==10) {
        errorlog.error(tools.lang({
          'en':'Please select an end date',
          'nl':'Gelieve een einddatum te selecteren',
          'fr':'Veuillez s\u00E9lectionner une date de fin'
        }));
      } else if(model.enddate<model.startdate) {
        errorlog.error(tools.lang({
          'en':'End date must be after start date',
          'nl':'Einddatum moet na de startdatum komen',
          'fr':'Date de fin doit etre apres la date de début'
        }));
      } else {
        var result;
        if($scope.isSpecificSale) {
          $scope.initHardcodedFormbuttoncomponents();
          var getAvailabilitiesParams={
            'lang':user.lang,
            'ffo':$scope.ffo,
            'startdate':tools.dateFromJava(model.startdate),
            'enddate':tools.dateFromJava(model.multipleDays==='0'?model.startdate:model.enddate),
            'formbutton':model.specificSaleFormbuttonId
          };
          $scope.gettingAvailabilities=true;
          result=webservice.call('getSpecificFormbuttonAvailabilities',getAvailabilitiesParams,script,model).then(function() {
            $scope.gettingAvailabilities=false;
          });
        } else {
          result=$q.resolve();
        }
        result.then(function() {
          $scope.incrementCurrentStepIndex();
          $scope.redirectToActivities();
        });
      }
    }
  };

  model.page='dates';
  model.initializing=true;
  $scope.dateOptions={
    minDate: new Date(),
    maxDate: new Date(2099,11,31),
    maxMode: 'day',
    startingDay: 1,
    showWeeks: false,
    initDate: new Date()
  };

  $scope.setMinAndMaxDateOptions = function(startdate,enddate) {
    var date=($scope.lastOrderHourPassed('00:15:00'))?(tools.addDays(new Date(),1)):(new Date());
    $scope.dateOptions.minDate=new Date(Math.max(tools.dateToJava(startdate),date));
    $scope.dateOptions.maxDate=tools.dateToJava(enddate);
    if(angular.isUndefined(model.startdate)) {
      $scope.dateOptions.initDate=new Date($scope.dateOptions.minDate);
    } else {
      $scope.dateOptions.initDate=new Date(model.startdate);
    }
  };

  $scope.getRequestId = function(yearAndMonth) {
    var requestId=false;
    if($scope.predateSelectors!==null && model.predateSelectorId!==null && angular.isDefined(model.predateSelectorId)) {
      requestId=yearAndMonth+model.predateSelectorId;
    } else if($scope.predateSelectors===null) {
      requestId=yearAndMonth;
    }
    return requestId;
  };

  $scope.dateIsAvailableInDateRange = function(date,maxEnddateOffset) {
    var available=false;
    var javaEnddate=new Date(date);
    javaEnddate.setHours(0,0,0,0);
    var javaStartdate=new Date(date);
    javaStartdate.setDate(javaStartdate.getDate()-maxEnddateOffset);
    javaStartdate.setHours(0,0,0,0);
    for(var d=new Date(javaStartdate.getTime());d<=javaEnddate;d.setDate(d.getDate()+1)) {
      var startdate=tools.dateFromJava(d);
      var newYearAndMonth=startdate.substr(0,7);
      var newRequestId=$scope.getRequestId(newYearAndMonth);
      if(newRequestId in model.availabilityCache && startdate in model.availabilityCache[newRequestId] && model.availabilityCache[newRequestId][startdate].fixedEnddates) {
        for(var enddate in model.availabilityCache[newRequestId][startdate].fixedEnddates) {
          if(tools.dateToJava(enddate)>=javaEnddate) {
            available=true;
            break;
          }
        }
      }
    }
    return available;
  };

  $scope.startdateIsAvailable = function(availability) {
    return availability.available && 'fixedEnddates' in availability && tools.getLength(availability.fixedEnddates)>0;
  };

  $scope.enddateIsAvailable = function(available,date,datePicker) {
    if(available) {
      var yearAndMonth,requestId,startdate;
      var enddate=tools.dateFromJava(date);
      if(angular.isDefined(model.startdate) && datePicker==='enddate') {
        startdate=tools.dateFromJava(model.startdate);
        yearAndMonth=startdate.substr(0,7);
        requestId=$scope.getRequestId(yearAndMonth);
        available=requestId in model.availabilityCache && startdate in model.availabilityCache[requestId] && model.availabilityCache[requestId][startdate].fixedEnddates && enddate in model.availabilityCache[requestId][startdate].fixedEnddates;
      } else {
        if(model.predateSelector!==null) {
          available=false;
          var javaEnddate=new Date(date);
          javaEnddate.setDate(javaEnddate.getDate()-model.predateSelector.minEnddateOffset);
          javaEnddate.setHours(0,0,0,0);
          var javaStartdate=new Date(date);
          javaStartdate.setDate(javaStartdate.getDate()-model.predateSelector.maxEnddateOffset);
          javaStartdate.setHours(0,0,0,0);
          for(var d=new Date(javaStartdate.getTime());d<=javaEnddate;d.setDate(d.getDate()+1)) {
            startdate=tools.dateFromJava(d);
            yearAndMonth=startdate.substr(0,7);
            requestId=$scope.getRequestId(yearAndMonth);
            available=requestId in model.availabilityCache && startdate in model.availabilityCache[requestId] && model.availabilityCache[requestId][startdate].fixedEnddates && enddate in model.availabilityCache[requestId][startdate].fixedEnddates;
            if(available) {
              break;
            }
          }
        }
      }
    }
    return available;
  };

  var pendingRequests={};
  $scope.datePickerAvailabilities = function(yearAndMonth,requestId) {
    if(!(requestId in pendingRequests)) {
      var today=new Date();
      var jStartdate,jEnddate;
      jStartdate=tools.dateToJava(yearAndMonth+'-01');
      if(jStartdate<today) {
        jStartdate=today;
      }
      jEnddate=tools.dateToJava(yearAndMonth+'-01');
      jEnddate.setMonth(jEnddate.getMonth()+1);
      if(jEnddate<today) {
        pendingRequests[requestId]=true;
      } else {
        pendingRequests[requestId]=webservice.call('getDatePickerAvailabilities',{
          'lang':user.lang,
          'startdate':tools.dateFromJava(jStartdate),
          'enddate':tools.dateFromJava(jEnddate),
          'ffo':$scope.ffo,
          'predateSelector':requestId.length>7?model.predateSelector:false,
          'formbutton':$scope.isSpecificSale?model.specificSaleFormbuttonId:false,
          'formbuttons':!$scope.isSpecificSale && tools.getLength(model.selectionFormbuttons)>0?model.selectionFormbuttons:false,
          'contactexternalids':tools.toArray(model.contactexternalids)
        },script,model).then(function(response) {
          model.availabilityCache[requestId]=response;
          $scope.$broadcast('refreshDatepickers');
        });
      }
    }
  };

  $scope.dateOptions.dateDisabled = function(data) {
    var today=new Date();
    var date=tools.dateToJava(tools.dateFromJava(data.date));
    var yearAndMonth=tools.dateFromJava(date).substr(0,7);
    var requestId=$scope.getRequestId(yearAndMonth);
    var mode=data.mode;
    var available=false;
    var day;
    if((requestId && requestId in model.availabilityCache) || mode==='year') {
      if(mode==='year') {
        available=date.getFullYear()>=today.getFullYear();
      } else if(mode==='month' && model.datepickerModus==='startdate') {
        var availabilities=model.availabilityCache[requestId];
        available=true;
        for(day in availabilities) {
          if($scope.startdateIsAvailable(availabilities[day])) {
            available=false;
            break;
          }
        }
      } else if(mode==='month' && model.datepickerModus==='enddate') {
        available=tools.dateFromJava(date).substr(0,7)>=tools.dateFromJava(today).substr(0,7);
        if(available) {
          var enddate=new Date(date.getTime());
          enddate.setMonth(enddate.getMonth()+1);
          for(var d=new Date(date.getTime());d<enddate;d.setDate(d.getDate()+1)) {
            available=$scope.enddateIsAvailable(true,d,'enddate');
            if(available) {
              break;
            }
          }
        }
      } else {
        if(model.datepickerModus==='startdate' || (angular.isDefined(model.startdate) && date<model.startdate)) {
          available=$scope.startdateIsAvailable(model.availabilityCache[requestId][tools.dateFromJava(data.date)]);
        } else {
          available=$scope.enddateIsAvailable(tools.dateFromJava(date)>=tools.dateFromJava(today),date,'enddate');
        }
      }
    } else if(requestId) {
      $scope.datePickerAvailabilities(yearAndMonth,requestId);
    }
    return !available;
  };

  $scope.dateOptions.customClass = function(data) {
    var classes=[];
    var today=new Date();
    today.setHours(0,0,0,0);
    var maxEnddateOffset=angular.isDefined(model.predateSelector) && 'maxEnddateOffset' in model.predateSelector?tools.numberIn(model.predateSelector.maxEnddateOffset):0;
    var date=tools.dateToJava(tools.dateFromJava(data.date));
    var yearAndMonth=tools.dateFromJava(data.date).substr(0,7);
    var requestId=$scope.getRequestId(yearAndMonth);
    var previousDate=tools.dateToJava(yearAndMonth+'-01');
    previousDate.setMonth(previousDate.getMonth()-1);
    var previousRequestId=$scope.getRequestId(tools.dateFromJava(previousDate).substr(0,7));
    if(tools.dateFromJava(date)===tools.dateFromJava(today)) {
      classes.push('current-day');
    }
    if(requestId && requestId in model.availabilityCache) {
      var availabilities=model.availabilityCache[requestId];
      var javaDate=tools.dateFromJava(data.date);
      var startdateAvailability,enddate;
      if(availabilities!==false && javaDate in availabilities) {
        var availability=availabilities[javaDate];
        if(angular.isDefined(model.startdate)) {
          if(model.startdate.getTime()===date.getTime()) {
            classes.push('active');
            classes.push('first-selected');
            if(maxEnddateOffset===0) {
              classes.push('last-selected');
            }
          } else {
            startdateAvailability=availabilities[tools.dateFromJava(model.startdate)];
            if(angular.isUndefined(startdateAvailability) && (previousRequestId in model.availabilityCache)) {
              startdateAvailability=model.availabilityCache[previousRequestId][tools.dateFromJava(model.startdate)];
            }
            if(angular.isDefined(startdateAvailability) && ('fixedEnddates' in startdateAvailability) && angular.isDefined(model.enddate)) {
              if(model.enddate.getTime()===date.getTime()) {
                classes.push('active');
                classes.push('last-selected');
              } else if(date>=model.startdate && date<=model.enddate) {
                classes.push('active');
              }
            }
          }
        }
        if(angular.isDefined(model.enddate) && tools.dateFromJava(model.enddate)===tools.dateFromJava(date)) {
          classes.push('active');
          classes.push('last-selected');
        }
        if(model.datepickerModus==='startdate' || (angular.isDefined(model.startdate) && date<model.startdate)) {
          if(model.multipleDays && (angular.isUndefined(model.startdate) || date<model.startdate || angular.isUndefined(model.enddate) || date>model.enddate)) {
            classes.push('datepicker-mode-startdate');
          }
          if($scope.dateIsAvailableInDateRange(date,maxEnddateOffset)) {
            classes.push('available');
          }
          if(availability.isStartdate) {
            classes.push('first','start-day');
            if($scope.dateIsAvailableInDateRange(tools.addDays(date,-1),maxEnddateOffset)) {
              classes.push('middle');
            }
          }
          if($scope.enddateIsAvailable(tools.dateFromJava(date)>=tools.dateFromJava(today),date,'startdate')) {
            classes.push('last');
            if($scope.dateIsAvailableInDateRange(tools.addDays(date,1),maxEnddateOffset)) {
              classes.push('middle');
            }
          }
        }
        if(model.datepickerModus==='enddate') {
          if(model.multipleDays && angular.isDefined(model.startdate) && date>model.startdate) {
            classes.push('datepicker-mode-enddate');
          }
          if(angular.isUndefined(model.startdate) && $scope.dateIsAvailableInDateRange(date,maxEnddateOffset)) {
            classes.push('available');
          } else {
            startdateAvailability=availabilities[tools.dateFromJava(model.startdate)];
            if(angular.isUndefined(startdateAvailability) && (previousRequestId in model.availabilityCache)) {
              startdateAvailability=model.availabilityCache[previousRequestId][tools.dateFromJava(model.startdate)];
            }
            if(angular.isDefined(startdateAvailability) && ('fixedEnddates' in startdateAvailability)) {
              for(enddate in startdateAvailability.fixedEnddates) {
                if(tools.dateToJava(enddate)>=date && model.startdate<=date) {
                  classes.push('available');
                  break;
                }
              }
            }
          }
          if(availability.isStartdate) {
            classes.push('first');
            if(angular.isUndefined(model.startdate) && $scope.dateIsAvailableInDateRange(tools.addDays(date,-1),maxEnddateOffset)) {
              classes.push('middle');
            } else {
              startdateAvailability=availabilities[tools.dateFromJava(model.startdate)];
              if(angular.isUndefined(startdateAvailability) && (previousRequestId in model.availabilityCache)) {
                startdateAvailability=model.availabilityCache[previousRequestId][tools.dateFromJava(model.startdate)];
              }
              if(angular.isDefined(startdateAvailability) && ('fixedEnddates' in startdateAvailability)) {
                for(enddate in startdateAvailability.fixedEnddates) {
                  if(tools.dateToJava(enddate)>date && model.startdate<date) {
                    classes.push('middle');
                    break;
                  }
                }
              }
            }
          }
          if($scope.enddateIsAvailable(tools.dateFromJava(date)>=tools.dateFromJava(today),date,'enddate')) {
            classes.push('available');
            classes.push('start-day');
            classes.push('last');
            if(angular.isUndefined(model.startdate) && $scope.dateIsAvailableInDateRange(tools.addDays(date,1),maxEnddateOffset)) {
              classes.push('middle');
            } else {
              startdateAvailability=availabilities[tools.dateFromJava(model.startdate)];
              if(angular.isUndefined(startdateAvailability) && (previousRequestId in model.availabilityCache)) {
                startdateAvailability=model.availabilityCache[previousRequestId][tools.dateFromJava(model.startdate)];
              }
              if(angular.isDefined(startdateAvailability) && ('fixedEnddates' in startdateAvailability)) {
                for(enddate in startdateAvailability.fixedEnddates) {
                  if(tools.dateToJava(enddate)>date && model.startdate<date) {
                    classes.push('middle');
                    break;
                  }
                }
              }
            }
          }
        }
      }
    } else if(requestId && data.mode!=='year') {
      $scope.datePickerAvailabilities(yearAndMonth,requestId);
    }
    return classes;
  };

  $scope.resetAvailabilityCache = function() {
    var loadTime=new Date();
    if(angular.isUndefined(model.availabilityCache) || (angular.isDefined(model.availabilityCache) && loadTime.getTime()-model.availabilityCache.lastLoaded.getTime()>60000*3)) {
      model.availabilityCache={'lastLoaded':loadTime};
    }
  };
  $scope.resetAvailabilityCache();

  $scope.$watch('model.date.getTime()',function(newVal,oldVal) {
    if(angular.isUndefined(model.datepickerModus)) {
      model.datepickerModus='startdate';
    }
    if(angular.isDefined(newVal)) {
      if(angular.isUndefined(model.startdate) || (angular.isDefined(model.startdate) && newVal<model.startdate.getTime()) || angular.isDefined(model.enddate)) {
        model.datepickerModus='startdate';
      } else {
        model.datepickerModus='enddate';
      }
      var datepickerModus=model.datepickerModus;
      if(newVal!==oldVal) {
        model[model.datepickerModus]=new Date(newVal);
        if(model.datepickerModus==='startdate') {
          if(model.startdate && model.predateSelectorId!==null) {
            var startdate=tools.dateFromJava(model.startdate);
            var requestId=startdate.substr(0,7)+model.predateSelectorId;
            if(requestId in model.availabilityCache && startdate in model.availabilityCache[requestId] && tools.getLength(model.availabilityCache[requestId][startdate].fixedEnddates)===1) {
              model.enddate=tools.dateToJava(tools.first(model.availabilityCache[requestId][startdate].fixedEnddates).enddate);
              delete model.date;
            } else {
              delete model.enddate;
              model.datepickerModus='enddate';
            }
          }
        } else {
          model.datepickerModus='startdate';
          delete model.date;
        }
        if(angular.isDefined(model.startdate) && angular.isDefined(model.enddate)) {
          $scope.onDateChange(datepickerModus,newVal);
        }
        $scope.$broadcast('refreshDatepickers');
      }
    }
  });

  $scope.$watch('model.predateSelectorId',function(newVal,oldVal) {
    model.predateSelector=$scope.predateSelectors[model.predateSelectorId];
    model.multipleDaysDatepicker=$scope.isMultipleDaysDatepicker();
    model.multipleDays=$scope.isMultipleDays();
    if(newVal!==oldVal) {
      delete model.startdate;
      delete model.enddate;
    }
    $scope.$broadcast('refreshDatepickers');
  });
  $timeout(function() {
    model.initializing=false;
  });

  $scope.loaded.then(function() {
    if(parameters.FFO[$scope.ffo].field2!=='') {
      $scope.setMinAndMaxDateOptions(parameters.FFO[$scope.ffo].field2,parameters.FFO[$scope.ffo].field3);
    } else if(parameters.FFO[$scope.ffo].field9!=='') {
      $scope.setMinAndMaxDateOptions(tools.dateFromJava($scope.startdateOptions.minDate),tools.dateFromJava($scope.startdateOptions.maxDate));
    }

    $scope.setCurrentStepIndexToMin();
  });
}]);
